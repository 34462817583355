<template>
  <!-- 现货订单 -->
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
				<div>
					<el-button type="primary" @click="ShowSearchClick">检索</el-button>
				</div>
        <div>
          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450"
            placeholder="请输入套数编码、系列名称、商品名称" v-model="retrieveForm.conditions">
            <el-button slot="append" type="primary" icon="el-icon-search" @click="submitSearch"></el-button>
          </el-input>
        </div>
        <div class="flex flex-ac">
          <div class="ml-40 fc-6b9eff fs-16 pointer" @click="exportExcel">
            <i class="el-icon-upload mr-10 fs-18"></i>导出
          </div>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        stripe @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }"
        :span-method="objectSpanMethod">
        <template v-slot:img="scope">
          <img :src="scope.row.userImg" alt="" class="smallImg">
        </template>
        <template v-slot:detailsImg="scope">
          <img :src="scope.row.detailsImg" alt="" class="smallImg">
        </template>
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableList" />
    </TableHeightFull>
		<el-dialog :visible.sync="isShowSearch" title="检索列表" size="80%" :with-header="false">
			<div class="retrieveDiv flex flex-ac flex-jb flex-w">
			  <div class="mr-30 flex flex-ac">
			    <span class="width-100 fs-16 flex1 mr-20">用户名：</span>
			    <el-input class="w-250" v-model="retrieveForm.userName" clearable></el-input>
			  </div>
			  <div class="mr-30 flex flex-ac">
			    <span class="width-100 fs-16 flex1 mr-20">手机号：</span>
			    <el-input class="w-250" v-model="retrieveForm.phone" clearable></el-input>
			  </div>
			  <div class="mr-30 mt-20 flex flex-ac">
			    <span class="width-100 fs-16 flex1 mr-20">订单编号：</span>
			    <el-input class="w-250" v-model="retrieveForm.orderNo" clearable></el-input>
			  </div>
			  <div class="mr-30 mt-20 flex flex-ac">
			    <span class="width-100 fs-16 flex1 mr-20">商户单号：</span>
			    <el-input class="w-250" v-model="retrieveForm.outTradeNo" clearable></el-input>
			  </div>
			  <div class="mr-30 mt-20 flex flex-ac">
			    <span class="width-100 fs-16 flex1 mr-20">人员类型：</span>
			    <el-select class="w-250" v-model="retrieveForm.isTest" clearable placeholder="请选择">
			      <el-option label="正式人员" :value="0">
			      </el-option>
			      <el-option label="测试人员" :value="1">
			      </el-option>
			    </el-select>
			  </div>
			  <div class="mr-30 mt-20 flex flex-ac">
			    <span class="width-100 fs-16 flex1 mr-20">创建时间：</span>
			    <el-date-picker class="w-250" v-model="startEndDate" type="daterange" valueFormat="yyyy-MM-dd"
			      range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="handPickerChange">
			    </el-date-picker>
			  </div>
			  <div class="mr-30 mt-20 flex flex-ac">
			    <span class="width-100 fs-16 flex1 mr-20">预售时间：</span>
			    <el-date-picker v-model="retrieveForm.dayDate" type="date" valueFormat="yyyy-MM-dd" placeholder="选择创建时间">
			    </el-date-picker>
			  </div>
			  <div class="mt-20">
			    <el-button type="primary" @click="submitSearch">查询</el-button>
			  </div>
			</div>
		</el-dialog>
    <!-- 使用优惠劵信息 -->
    <el-dialog title="优惠劵信息" :visible.sync="dialogUseVisible">
      <div class="">
        <EleTable ref="tableRef" slot="table" :tableData="perspectiveUseData" :columns="couponColumns" width='100%'
          max-height="500" :border="false" stripe>
        </EleTable>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogUseVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogUseVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
import { mapState } from 'vuex'
export default {
  name: 'drawsGood',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "CountDown": (resolve) => require(["@/components/CountDown"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'goodsTypes': state => state.dictList ? state.dictList.goods_type : [],  //商品类型
      'couponsTypes': state => state.dictList ? state.dictList.coupons_type : [],  //优惠劵类型
    })
  },
  data () {
    return {
      retrieveForm: {
        conditions: '', //整合条件
        // pageNo: '', //套数编码
        // detailsName: '', //商品名称,模糊匹配
        // seriesName: '', //系列名称,模糊匹配

        orderNo: '', //订单号
        outTradeNo: '', //商户订单号
        userName: '', //用户名称 模糊匹配
        tradingType: 2, //商品状态
        phone: '', //用户手机号,模糊匹配
        isTest: 0, //是否测试
        startDate: '', //开始时间
        endDate: '', //	结束时间
        dayDate: '', //创建时间
      },
      startEndDate: [], //时间区间
      tabLoading: false,
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', fixed: 'left', index: this.indexMethod },
				{ label: '用户名', prop: 'userName', align: 'left', width: '100', 'show-overflow-tooltip': true },
        { label: '订单编号', prop: 'orderNo', align: 'left', width: '120' },
        // { columnType: 'custom', label: '头像', prop: 'img', align: 'left', width: '80' },
        { label: '手机号', prop: 'userPhone', align: 'left', width: '140' },
        { label: '商户单号', prop: 'outTradeNo', align: 'left', width: '160' },
        { label: '现金', prop: 'wxMoney', align: 'center', width: '80' },
        { label: '余额', prop: 'useBlance', align: 'center', width: '80' },
        { label: '优惠劵', prop: 'couponsMoney', align: 'center', width: '70' },
        { label: '系列价格', prop: 'seriesPrice', align: 'center', width: '90' },
        { label: '系列名称', prop: 'seriesName', align: 'left', width: '170' },
        {
          columnType: 'fun', label: '系列类型', prop: 'goodsType', align: 'left', width: '80', formatData: (e) => {
            let ele = this.goodsTypes.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        { label: '商品名称', prop: 'detailsName', align: 'left', width: '170' },
        { columnType: 'custom', label: '商品图片', prop: 'detailsImg', align: 'left', width: '80' },
        { label: '创建时间', prop: 'createDate', align: 'left', width: '170', },
        { label: '预售时间', prop: 'deliverDate', align: 'left', width: '120', },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '120',
          buttons: [
            { type: 'text', text: '优惠劵', fn: this.findCouponsData, hidden: (e) => { return e.couponsMoney } },
            { type: 'text', text: '删除', fn: this.deleteList, hidden: (e) => { return this.isButFlag } }
          ],
        },
      ],
      tableData: [],
      //使用详情
      dialogUseVisible: false,
      perspectiveUseData: "",
      couponColumns: [
        { label: '优惠劵面额', prop: 'money', align: 'center', width: '120' },
        { label: '优惠劵来源', prop: 'couponsName', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'fun', label: '优惠劵类型', prop: 'couponsType', align: 'left', width: '100', formatData: (e) => {
            let ele = this.couponsTypes.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        { label: '门槛', prop: 'doorSill', align: 'center', width: '60' },
        { label: '使用时间', prop: 'updateDate', align: 'center', width: '160' },
        { label: '创建时间', prop: 'createDate', align: 'center', width: '160' },
        { label: '结束时间', prop: 'endDate', align: 'center', width: '160' },
      ],
      //按钮
      isButFlag: [],
			isShowSearch:false
    }
  },
  mounted () {
    let pageButtons = this.$route.meta.buttons
    if (pageButtons) {
      let index = pageButtons.findIndex(item => item.name == '删除')
      if (index == -1) this.isButFlag = false
    }
    this.getTableList()
  },
  methods: {
    //查询
    submitSearch () {
      this.pageInfo.pageNum = 1
			this.isShowSearch = false
      this.getTableList()
    },
		//是否显示检索功能
		ShowSearchClick(){
			this.isShowSearch = !this.isShowSearch
		},
    //导出
    exportExcel () {
      this.$http.post('/tradingOrder/exceportOrderNew', {
        ...this.retrieveForm
      }, {
        responseType: "blob", // 1.首先设置responseType对象格式为 blob:
      }).then((result) => {
        let blob = new Blob([result.data], {
          type: "application/vnd.ms-excel",
        })
        // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob) // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a")
        a.href = url
        a.download = "现货订单.xls"
        a.click()
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url)
      }).catch((err) => { })
    },
    //时间变化
    handPickerChange (value) {
      if (value && value.length != 0) {
        this.retrieveForm.startDate = value[0]
        this.retrieveForm.endDate = value[1]
      } else {
        this.retrieveForm.startDate = ""
        this.retrieveForm.endDate = ""
      }
    },
    //获取表格数据
    getTableList () {
      this.tabLoading = true
      this.$http.get('/tradingOrder/findTradingOrderList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          ...this.retrieveForm
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
        this.getSpanArr(this.tableData)
      })
    },
    //删除
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/tradingOrder/removeTradingOrder', {
          id: row.id,
        }).then(({ data: result }) => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getTableList()
        })
      }).catch(_ => { })
    },
    //跳转详情
    headerRowClick (row) { },
    //查询优惠劵
    findCouponsData (row) {
      this.$http.get('/tradingOrder/findCouponsByTradingId', {
        params: {
          tradingId: row.tradingId,
        }
      }).then((result) => {
        this.dialogUseVisible = true
        this.perspectiveUseData = result.data.data
      }).catch((err) => { })
    },
    //数据合并比较
    getSpanArr (data) {
      this.spanArr = []
      this.pos = 0
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArr.push(1)
          this.pos = 0
        } else {
          if (data[i].orderNo === data[i - 1].orderNo) {
            // 如果date相等就累加，并且push 0 （这里的判断视自己的判定依据改变）
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
            // 不相等push 1
            this.spanArr.push(1)
            this.pos = i
          }
        }
      }
    },
    //实现合并行或列
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
        const cRow = this.spanArr[rowIndex]
        const cCol = cRow > 0 ? 1 : 0
        return {
          rowspan: cRow, // 合并的行数
          colspan: cCol // 合并的列数，为0表示不显示
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .gray-row {
  background: #e0e0e0 !important;
}
.width-100 {
	width: 100px;
	text-align: right;
}
</style>