import { render, staticRenderFns } from "./wxPayMan.vue?vue&type=template&id=9872ddda&scoped=true&"
import script from "./wxPayMan.vue?vue&type=script&lang=js&"
export * from "./wxPayMan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9872ddda",
  null
  
)

export default component.exports