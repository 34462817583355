<template>
	<el-drawer title="助力活动详情" :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
		:modal-append-to-body="false" :wrapperClosable="false" :close-on-press-escape="false">
		<div class="h-full flex flex-dc">
			<div class="drawer_title pall-30 flex flex-jb flex-n">
				<div class="fs-18 fw-bold">助力活动详情</div>
				<i class="el-icon-close fs-20" @click="handleClose"></i>
			</div>
			<div class="flex-1 overflow-scroll-y pall-30">
				<el-form :inline="true" ref="formActivity" :model="formActivity" :rules="rules" class="demo-form-inline">
					<el-form-item label="区间名称" prop="areaName">
						<el-input v-model="formActivity.areaName" type="text" placeholder="区间名称"></el-input>
					</el-form-item>
					<el-form-item label="区间开始值" prop="areaStartNum">
						<el-input v-model="formActivity.areaStartNum" type="number" placeholder="区间开始值"></el-input>
					</el-form-item>
					<el-form-item label="区间结束值" prop="areaEndNum">
						<el-input v-model="formActivity.areaEndNum" type="number" placeholder="区间结束值"></el-input>
					</el-form-item>
					<el-form-item label="服装图片" prop="areaImg">
						<upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
							iconName="el-icon-upload" iconSize="16" v-model="formActivity.areaImg">
						</upload-file>
					</el-form-item>
					<el-form-item label="是否最大区间" prop="isMaxArea">
						<el-select v-model="formActivity.isMaxArea" placeholder="优惠劵类型">
							<el-option label="否" value="false"></el-option>
							<el-option label="是" value="true"></el-option>
						</el-select>
					</el-form-item>
					<el-card class="mb-30" v-for="(item,index) in formActivity.taskList" :key="index">
						<div slot="header" class="flex flex-ac flex-jb">
							<div>操作</div>
							<el-button style="float: right; padding: 3px 0" type="text" @click="delRanking(index,item)"
								v-if="formActivity.taskList.length > 1">删除 </el-button>
						</div>
						<el-form-item label="任务名称" :prop="'taskList.' + index + '.taskName'"
							:rules="{required: true,message: '请输入任务名称',trigger: 'blur'}">
							<el-input v-model="item.taskName" type="text" placeholder="请输入名称"></el-input>
						</el-form-item>
						<el-form-item label="任务类型" :prop="'taskList.' + index + '.taskType'"
							:rules="{required: true,message: '请输入任务类型	',trigger: 'blur'}">
							<el-select v-model="item.taskType" placeholder="优惠劵类型">
								<el-option v-for="(ite,inde) in taskTypes" :label="ite.key" :value="ite.value" :key="inde"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="任务周期" :prop="'taskList.' + index + '.taskCycle'"
							:rules="{required: true,message: '请输入周期	',trigger: 'blur'}">
							<el-select v-model="item.taskCycle" placeholder="优惠劵类型">
								<el-option label="月" value="month"></el-option>
								<el-option label="周" value="weeks"></el-option>
								<el-option label="日" value="day"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="任务限制次数	" :prop="'taskList.' + index + '.taskMax'"
							:rules="{required: true,message: '请输入限制次数	',trigger: 'blur'}">
							<el-input v-model="item.taskMax" type="text" placeholder="请输入限制次数"></el-input>
						</el-form-item>
						<el-form-item label="下单限制次数" :prop="'taskList.' + index + '.ccr'"
							:rules="{required: true,message: '请输入下单限制次数',trigger: 'blur'}">
							<el-input v-model="item.ccr" type="number" :min="0" placeholder="下单限制次数"></el-input>
						</el-form-item>
						<el-form-item label="经验值" :prop="'taskList.' + index + '.expValue'"
							:rules="{required: true,message: '请输入下单限制次数',trigger: 'blur'}">
							<el-input v-model="item.expValue" type="number" :min="0" placeholder="下单限制次数"></el-input>
						</el-form-item>
						<el-button style="margin-bottom: 20px;float: right;" type="primary" @click="addProp(item)">新增道具</el-button>
						<el-table :data="item.propList" border style="width: 100%">
							<el-table-column label="道具名称">
								<template slot-scope="scope">
									<el-form-item prop="propName" :prop="'taskList.' + index + '.propList.' + scope.$index + '.propName'"
										:rules="{required: true,message: '请输入道具名称',trigger: 'change'}">
										<el-input v-model="scope.row.propName" type="text" placeholder="道具名称"></el-input>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="道具类型">
								<template slot-scope="scope">
									<el-form-item :prop="'taskList.' + index + '.propList.' + scope.$index + '.propType'"
										:rules="{required: true,message: '请输入道具类型',trigger: 'change'}">
										<el-select v-model="scope.row.propType" placeholder="请选择类型" clearable>
											<el-option v-for="(item,index) in propTypes" :label="item.key" :value="item.value"
												:key="index"></el-option>
										</el-select>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="道具图片" prop="propImg">
								<template slot-scope="scope">
									<el-form-item :prop="'taskList.' + index + '.propList.' + scope.$index + '.propImg'"
										:rules="{required: true,message: '请上传道具图片',trigger: 'blur'}">
										<upload-file upStyle="width: 70px; height: 70px; border-radius: 5px; overflow: hidden;"
											iconName="el-icon-upload" iconSize="16" v-model="scope.row.propImg">
										</upload-file>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="数量" prop="num">
								<template slot-scope="scope">
									<el-form-item prop="num" :prop="'taskList.' + index + '.propList.' + scope.$index + '.num'"
										:rules="{required: true,message: '请输入数量',trigger: 'blur'}">
										<el-input v-model="scope.row.num" type="number" :min="0" placeholder="兑换数量"></el-input>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="有效天数" prop="validDay">
								<template slot-scope="scope">
									<el-form-item prop="validDay" v-if="scope.row.propType !== 1 && scope.row.propType !== 6"
										:prop="'taskList.' + index + '.propList.' + scope.$index + '.validDay'"
										:rules="{required: true,message: '请输入有效天数',trigger: 'blur'}">
										<el-input v-model="scope.row.validDay" type="number" :min="0" placeholder="有效天数">
										</el-input>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="面额" prop="money">
								<template slot-scope="scope">
									<el-form-item prop="money" v-if="scope.row.propType == 4">
										<el-input v-model="scope.row.money" type="number" :min="0" placeholder="面额"></el-input>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="门槛" prop="doorSill">
								<template slot-scope="scope" v-if="scope.row.propType == 4">
									<el-form-item prop="doorSill">
										<el-input v-model="scope.row.doorSill" type="number" :min="0" placeholder="门槛">
										</el-input>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="优惠劵类型" prop="couponsType">
								<template slot-scope="scope">
									<el-form-item prop="couponsType" v-if="scope.row.propType == 4">
										<el-select v-model="scope.row.couponsType" placeholder="优惠劵类型">
											<el-option v-for="(item,index) in couponsTypes" :label="item.key" :value="item.value"
												:key="index"></el-option>
										</el-select>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column fixed="right" label="操作" width="100">
								<template slot-scope="scope">
									<el-form-item>
										<el-button @click="delProp(scope.$index,item.propList)" type="text" size="small"
											v-if="item.propList.length > 1">删除 </el-button>
									</el-form-item>
								</template>
							</el-table-column>
						</el-table>
					</el-card>
					<div class="ranking_but w-full fs-18 fw-bold ph-16 flex flex-ac flex-jc" @click="addRanking">新增任务 </div>
				</el-form>
			</div>
			<div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
				<!-- {{goodId?'保存':'提交'}} :loading="butLoading"-->
				<el-button type="primary" @click="submitForm">{{goodId?'保存':'新增'}}</el-button>
				<el-button type="primary" plain @click="handleClose">取消</el-button>
			</div>
		</div>
	</el-drawer>
</template>
<script>
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'helpActivityDetails',
		components: {
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
		},
		computed: {
			...mapState('menu', {
				'propTypes': state => state.dictList ? state.dictList.prop_type : [], //道具类型
				'couponsTypes': state => state.dictList ? state.dictList.coupons_type : [], //优惠劵类型
				'taskTypes': state => state.dictList ? state.dictList.task_type : [], //优惠劵类型
			})
		},
		data() {
			return {
				isDrawer: false,
				seriesList: [], //系列列表
				goodId: '', //详情id
				butLoading: false,
				formActivity: {
					areaName: '',
					areaStartNum: '',
					areaEndNum: '',
					areaImg: '',
					isMaxArea: 'false',
					taskImg: '',
					id: '', //系列id
					taskList: [{
						taskName: '',
						taskType: '',
						ccr: '',
						expValue: '',
						chickAreaId: '',
						taskCycle: '',
						taskMax: '',
						propList: [{
							propType: '',
							propName: '',
							validDay: '',
							money: '',
							doorSill: '',
							couponsType: '',
							num: '',
							propImg: '',
						}]
					}]
				},
				rules: {
					areaName: {
						required: true,
						message: '请输入区间名称',
						trigger: 'blur'
					},
					areaStartNum: {
						required: true,
						message: '请输入区间开始值',
						trigger: 'change'
					},
					areaEndNum: {
						required: true,
						message: '请输入区间结束值',
						trigger: 'change'
					},
					areaImg: {
						required: true,
						message: '请上传服装图片',
						trigger: 'blur'
					},
				},
			}
		},
		mounted() {
			this.findBoxSeries()
		},
		methods: {
			init(id) {
				this.isDrawer = true
				if (id) {
					this.goodId = id
					this.getDetails(id)
				}
			},
			changeValue(val) {
				console.log(val, "changeValue")
			},
			//查询所有抽盒系列
			findBoxSeries() {
				this.$http.get('/boxSeries/findBoxSeries').then(({
					data: result
				}) => {
					this.seriesList = result.data
				}).catch(err => {})
			},
			//获取详情数据
			getDetails(id) {
				this.$http.get(`/chickArea/findChickAreaById?id=${id}`).then(({
					data: res
				}) => {
					let goodData = res.data
					// if (goodData.detailsList == null || goodData.detailsList == undefined || goodData
					// 	.detailsList == "") {
					// 	goodData.detailsList = []
					// }
					this.formActivity = goodData
					console.log(goodData, "goodData")
				})
			},
			//关闭弹框
			handleClose() {
				this.isDrawer = false
				this.resetForm()
			},
			//开始时间处理
			startChangDate(val) {
				console.log(val, "val")
				if (val) {
					this.formActivity.startDate = val
				} else {
					this.formActivity.startDate = ''
				}
			},
			//结束时间处理
			endChangDate(val) {
				if (val) {
					this.formActivity.endDate = val
				} else {
					this.formActivity.endDate = ''
				}
			},
			//新增排名
			addRanking() {
				console.log(this.formActivity.taskList, "this.formActivity.ranks")
				this.formActivity.taskList.push({
					rankRange: '',
					passingLine: '',
					propList: [{
						propType: '',
						propName: '',
						validDay: '',
						money: '',
						doorSill: '',
						couponsType: '',
						num: '',
						propImg: '',
					}]
				})
			},
			//删除排名
			delRanking(index, ite) {
				// console.log(index,ite,"index,ite")
				this.formActivity.taskList.splice(index, 1)
			},
			//新增道具
			addProp(ele) {
				ele.propList.push({
					propType: '',
					propName: '',
					validDay: '',
					money: '',
					doorSill: '',
					couponsType: '',
					num: '',
					propImg: '',
				})
			},
			//删除道具
			delProp(inx, rows) {
				rows.splice(inx, 1)
			},
			//编辑
			editOkSubmit() {
				let goodData = JSON.stringify(this.formActivity)
				console.log(goodData, "this.formActivity")
				// goodData.json = JSON.stringify(goodData.detailsList)
				// delete goodData.detailsList
				// goodData.detailsList = JSON.stringify(goodData.detailsList)
				// console.log(goodData.detailsList, 'json')
				let apiUrl = ''
				if (this.goodId) { //修改
					apiUrl = '/chickArea/editChickArea'
				} else { //新增
					apiUrl = '/chickArea/addChickArea'
				}
				this.$http.post(apiUrl, goodData, {
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(res => {
					this.$message({
						message: this.goodId ? '修改成功' : '新增成功',
						type: 'success'
					})
					this.$emit('refresh')
					this.handleClose()
				}).catch(err => {})
			},
			//提交
			submitForm() {
				this.$refs['formActivity'].validate((valid) => {
					if (valid) {
						if (!valid) return
						console.log("最终数据", this.formActivity)
						this.editOkSubmit()
					}
				})
			},
			// 重置form表单
			resetForm() {
				Object.assign(this.$data.formActivity, this.$options.data.call(this).formActivity)
				console.log(this.$refs.formActivity, "this.$refs.formActivity")
				this.$nextTick(() => {
					this.$refs.formActivity.clearValidate()
				})
			},
			// 重置form表单
			// resetForm() {
			// 	Object.assign(this.$data.formActivity, this.$options.data.call(this).formActivity)
			// 	this.$refs.formActivity.clearValidate()
			// },
		}
	}
</script>
<style lang="less" scoped>
	.ranking_but {
		border: 1px dashed #409eff;
		border-radius: 20px;
		color: #409eff;
	}
</style>