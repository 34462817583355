<template>
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div class="flex flex-ac">
        </div>
        <div class="flex flex-ac">
          <div class="ml-40 fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
        <template v-slot:image="scope">
          <img :src="scope.row.cardImg" alt="" class="smallImg" v-if="scope.row.cardImg" />
        </template>
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
    </TableHeightFull>
    <!-- 新增修改弹框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="20vh" width="30%" @close="resetForm">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="小程序id" prop="deptAppId" v-if="'deptAppId' in ruleForm"
          :rules="{required: true, message: '小程序id不能为空', trigger: 'blur'}">
          <el-input type="text" v-model="ruleForm.deptAppId"></el-input>
        </el-form-item>
				<el-form-item label="小程序名称" prop="deptName" v-if="'deptName' in ruleForm"
				  :rules="{required: true, message: '小程序名称不能为空', trigger: 'blur'}">
				  <el-input type="text" v-model="ruleForm.deptName"></el-input>
				</el-form-item>
				<el-form-item label="发货开关" prop="sendMsgOff">
					<el-switch v-model="ruleForm.sendMsgOff" :active-value="1" :inactive-value="0"></el-switch>
				</el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 修改时间 -->
    <el-dialog title="修改" :visible.sync="dialogDateTime" top="20vh" width="30%">
      <div class="pv-30">
        <el-date-picker v-model="cardEndDate" type="datetime" clearable placeholder="选择日期时间" style="width: 100%;"
          value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59">
        </el-date-picker>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogDateTime = false">取 消</el-button>
        <el-button type="primary" @click="affirmDate" :loading="butLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
export default {
  name: 'gatherCard',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
  },
  data () {
    return {
      retrieveForm: {
        searchValue: '',
      },
      tabLoading: false,
      butLoading: false,
      columns: [
				{ label: 'id', prop: 'id', align: 'left', 'show-overflow-tooltip': true },
        { label: '小程序id', prop: 'deptAppId', align: 'left', 'show-overflow-tooltip': true },
        { label: '小程序名称', prop: 'deptName', align: 'left', 'show-overflow-tooltip': true },
				{
					columnType: 'fun',
					label: '是否开启发货',
					prop: 'sendMsgOff',
					align: 'left',
					'show-overflow-tooltip': true,
					formatData: (e) => {
						return e == 0 ? '否':'是'
					}
				}, 
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '150',
          buttons: [
            { type: 'text', text: '编辑', fn: this.editList },
            { type: 'text', text: '删除', fn: this.deleteList },
          ],
        },
      ],
      tableData: [],
      systemData: '',//活动时间
      //弹框
      dialogFormVisible: false,
      dialogTitle: '新增',
      ruleForm: {
        deptAppId: '',
        deptName: '',  
				sendMsgOff:'0'
      },
      dialogDateTime: false,
      cardEndDate: '',
      seriesList: [],//商品系列列表
    }
  },
  mounted () {
    console.log(window.Glob)
    this.getTableData()
    this.findSystemConfig()
  },
  methods: {
    //获取表格数据
    getTableData () {
      this.tabLoading = true
      this.$http.get('/department/findDepartmentList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      }).catch(err => {
        this.tabLoading = false
      })
    },
    //跳转详情
    headerRowClick (row) {
      console.log('跳转详情')
      console.log(row)
    },
    //新增列表
    addList () {
      this.dialogTitle = "新增"
      this.dialogFormVisible = true
    },
    //编辑列表
    editList (row) {
      this.dialogTitle = "编辑"
      this.ruleForm = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
    },
    //删除列表
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/department/removeDepartmentById', { id: row.id }).then(({ data: result }) => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getTableData()
        })
      }).catch(_ => { })
    },
    //确认弹框
    affirmPopup () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.butLoading = true
          let apiUrl = ""
          if (this.dialogTitle == '新增') {
            apiUrl = "/department/addDepartment"
          } else if (this.dialogTitle == '编辑') {
            apiUrl = "/department/editDepartment"
          }
          this.$http.post(apiUrl, this.ruleForm).then(({ data: result }) => {
            this.$message({
              message: this.dialogTitle + '成功',
              type: 'success'
            })
            this.butLoading = false
            this.getTableData()
            this.dialogFormVisible = false
          }).catch((err) => {
            this.butLoading = false
          })
        }
      })
    },
    //重置清除校验
    resetForm () {
      Object.assign(
        this.$data.ruleForm,
        this.$options.data().ruleForm
      )
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
  },
}
</script>

<style lang="less" scoped>
</style>