import axios from 'axios'
import router from '@/router'
import qs from 'qs'
import { Loading, Message } from 'element-ui'
import { clearLoginInfo } from '@/utils'
import isPlainObject from 'lodash/isPlainObject'

const http = axios.create({
  baseURL: window._config.axiosConfig.baseURL,
  timeout: 1000 * 60,
  withCredentials: true,
})
if (process.env.NODE_ENV == 'development') {
  let utl = window._config.axiosConfig.baseURL
  let utlList = utl.split('.com')
  if (utlList.length > 1) {
    http.defaults.baseURL = utlList[1]
  }
}

// 请求拦截器
http.interceptors.request.use(
  (config) => {
    startLoading()
    axios.defaults.headers.post['Content-Type'] = config.contentType || 'application/x-www-form-urlencoded'
    // config.headers['content-type'] = config.contentType ? config.contentType : 'application/x-www-form-urlencoded'
	  // :"application/json"
      
    var defaults = {}
    if (isPlainObject(config.params)) {
      config.params = {
        ...defaults,
        ...config.params,
      }
    }
    if (isPlainObject(config.data)) {
      config.data = {
        ...defaults,
        ...config.data,
      }
      if (
        /^application\/x-www-form-urlencoded/.test(
          axios.defaults.headers.post['Content-Type']
        )
      ) {
        config.data = qs.stringify(config.data)
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
http.interceptors.response.use(
  (response) => {
    if (
      response.config.responseType == 'arraybuffer' ||
      response.config.responseType == 'blob'
    ) {
      return response
    } else {
      if (response.status == '200') {
        endLoading()
        if (response.data.code == 2) {
          clearLoginInfo()
          if (window.location.pathname == '/login') {
            router.go(0)
          } else {
            router.replace({ name: 'login' })
          }
          Message({
            type: 'error',
            message: response.data.msg,
          })
          return Promise.reject(response.data.msg)
        } else if (response.data.code == 0) {
          Message({
            type: 'error',
            message: response.data.msg,
          })
          return Promise.reject(response.data.msg)
        }
        return response
      } else {
        endLoading()
        Message({
          type: 'error',
          message: '网络请求异常',
        })
      }
    }
  },
  (error) => {
    endLoading()
    Message({
      type: 'error',
      message: '您的网络请求异常' + error,
    })
    console.error(error)
    return Promise.reject(error)
  }
)

function startLoading() {
  // 开始加载动画
}

function endLoading() {
  // 结束加载动画
}

export default http
