import { render, staticRenderFns } from "./mangoManure.vue?vue&type=template&id=61803deb&scoped=true&"
import script from "./mangoManure.vue?vue&type=script&lang=js&"
export * from "./mangoManure.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61803deb",
  null
  
)

export default component.exports