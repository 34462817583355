<template>
	<div class="ele-table">
		<el-table ref="table" :header-cell-style="{background:'#ecf5ff'}" v-bind="$attrs" :data="tableData"
			style="width: 100%" v-on="$listeners" v-show="tableData.length > 0 && !loading">
			<template v-for="(item, index) in columns">
				<!-- 自定义列 -->
				<el-table-column v-if="item.columnType == 'custom'" :key="index" v-bind="item">
					<template slot-scope="scope">
						<slot :row="scope.row" :name="item.prop" :index="scope.$index">
						</slot>
					</template>
				</el-table-column>
				<!-- button列 -->
				<el-table-column v-else-if="item.columnType == 'button'" v-bind="item" :key="index">
					<template slot-scope="scope">
						<template v-for="(btn, btnIndex) in item.buttons">
							<el-button :key="btnIndex" type="text" :size="btn.size"
								:disabled="btn.disabled ? btn.disabled(scope.row) : false"
								@click.stop="btn.fn(scope.row, scope.$index, btnIndex, btn)"
								v-if="btnConditions(scope.row, btn.hidden) && btn.type === 'text'">
								<span v-if="btn.html" v-html="btn.text"></span>
								<span v-else v-text="btn.text"></span>
							</el-button>
							<el-dropdown v-if="btn.type === 'dropdown'">
								<el-button type="text" class="ml-10 flex flex-ac">
									{{ btn.text }}<i class="el-icon-arrow-down el-icon--right"></i>
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-for="(item, index) in btn.items" :key="index"
									v-if="btnConditions(scope.row, item.hidden)" 
										@click.native="item.fn(scope.row,scope.$index, index, item)">
										{{ item.text }}
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</template>
				</el-table-column>
				<!-- 自定义文本列 -->
				<el-table-column v-else-if="item.formatData" v-bind="item" :key="index">
					<template slot-scope="scope">
						<span v-if="item.formatData && item.html" v-html="item.formatData(scope.row[item.prop], scope.row)"></span>
						<span v-else-if="item.formatData" v-text="item.formatData(scope.row[item.prop], scope.row)"></span>
						<span v-else v-text="scope.row[item.prop]"></span>
					</template>
				</el-table-column>
				<!-- 普通列 -->
				<el-table-column v-else v-bind="item" :key="index" />
			</template>
		</el-table>
		<!-- 空数据 -->
		<div class="empty" v-show="!loading && tableData.length === 0">
			<el-empty description="暂无数据">
			</el-empty>
		</div>
		<!-- 加载中动画 -->
		<transition>
			<div v-show="loading " class="loading">
				<el-skeleton :loading="loading" :rows="6" animated />
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		name: "EleTable",
		props: {
			columns: {
				type: Array,
				default () {
					return []
				}
			},
			tableData: {
				type: Array,
				default () {
					return []
				}
			},
			// 是否正在加载数据
			loading: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			/**
			 * 按钮根据条件进行隐藏
			 * @param row 行数据
			 * @param isHidden 隐藏条件fn
			 */
			btnConditions(row, isHidden) {
				if (typeof isHidden === "function") {
					return isHidden(row)
				}
				return !isHidden
			},
			selectedStyle() {},
			tableRowClassName({
				row,
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'statistics-warning-row'
				} else {
					return 'statistics-warning-row2'
				}
			}
		},
	};
</script>

<style scoped lang="less">
	.ele-table {
		height: 100%;
	}

	::v-deep .el-button--mini {
		padding: 5px 7px;
	}

	::v-deep .el-button.el-button--primary {
		padding: 12px 18px;
		font-size: 16px !important;
	}
</style>