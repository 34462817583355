<template>
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div class="flex flex-ac">
<!--          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue">
            <el-button class="fc-0075F6" slot="append" type="primary" icon="el-icon-search" @click="submitSearch">
            </el-button>
          </el-input> -->
          <!-- <el-button type="primary" class="ml-40" @click="submitForm">保存</el-button> -->
        </div>
        <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="newWealColumns" height="100%"
        :border="false" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
        <template v-slot:img="scope">
          <img :src="scope.row.fodderImg" alt="" class="smallImg" v-if="scope.row.fodderImg">
        </template>
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
    </TableHeightFull>
    <!-- 每日福利 新人活动 道具 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="10vh" width="30%" @close="resetForm">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="肥料名称" prop="fodderName" v-if="'fodderName' in ruleForm"
          :rules="{required: true, message: '道具名称不能为空', trigger: 'blur'}">
          <el-input v-model="ruleForm.fodderName"></el-input>
        </el-form-item>
				<el-form-item label="经验值" prop="fodderExp" v-if="'fodderExp' in ruleForm"
				  :rules="{required: true, message: '道具名称不能为空', trigger: 'blur'}">
				  <el-input v-model="ruleForm.fodderExp"></el-input>
				</el-form-item>
        <el-form-item label="肥料图片" prop="fodderImg" v-if="'fodderImg' in ruleForm"
          :rules="{required: true, message: '图片不能为空', trigger: 'blur'}">
          <UploadFile upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
            iconName="el-icon-upload" iconSize="26" v-model="ruleForm.fodderImg"></UploadFile>
        </el-form-item>
        <el-form-item label="肥料等级" prop="fodderSort" v-if="'fodderSort' in ruleForm"
          :rules="{required: true, message: '肥料等级不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.fodderSort"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
import { mapState } from 'vuex'
export default {
  name: 'turnplate',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'propTypes': state => state.dictList ? state.dictList.prop_type : [],  //道具类型
      'couponsTypes': state => state.dictList ? state.dictList.coupons_type : [],  //优惠劵类型
    })
  },
  data () {
    return {
      retrieveForm: {
        searchValue: '',
      },
      configData: '',//默认转盘次数
      tabLoading: false,
      butLoading: false,
      newWealColumns: [
        { type: 'index', label: '序号', width: '60', align: 'center' },
				{ columnType: 'custom', label: '肥料图片', prop: 'img', width: '80' },
				{ label: '肥料名称', prop: 'fodderName', align: 'left', 'show-overflow-tooltip': true },
        { label: '经验值', prop: 'fodderExp', align: 'left', 'show-overflow-tooltip': true },
        { label: '等级', prop: 'fodderSort', align: 'left'},
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right',
          buttons: [
            { type: 'text', text: '编辑', fn: this.editList },
            { type: 'text', text: '删除', fn: this.deleteList }
          ],
        },
      ],
      tableData: [],
      //弹框
      dialogTitle: '新增',
      dialogFormVisible: false,
      ruleForm: {
        fodderExp: '',//饲料经验值
        fodderName: '',//饲料名称
        fodderSort: '', //饲料排序
        fodderImg: '',//道具图片
				id:''
      },
    }
  },
  mounted () {
    this.getTableData()
    this.findSystemConfig()
  },
  methods: {
    //查询
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableData()
    },
    //获取表格数据
    getTableData () {
      this.tabLoading = true
      this.$http.get('/chickFodder/findChickFodderList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      }).catch(err => {
        this.tabLoading = false
      })
    },
    //查询基础配置
    findSystemConfig () {
      this.$http.get('/systemConfig/findSystemConfig').then((result) => {
        let configList = result.data.data
        if (configList.length > 0) {
          this.configData = configList[0]
        }
      }).catch((err) => { })
    },
    //保存 默认次数
    submitForm () {
      this.$http.post('/systemConfig/editSystemConfig', this.configData).then((result) => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.findSystemConfig()
      }).catch((err) => { })
    },
    //新增列表
    addList () {
      this.dialogTitle = "新增"
      this.dialogFormVisible = true
    },
    //编辑
    editList (row) {
      this.dialogTitle = "编辑"
      this.ruleForm = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
    },
    //确认弹框
    affirmPopup () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.butLoading = true
          let apiUrl = ""
          if (this.dialogTitle == '新增') {
            apiUrl = "/chickFodder/addChickFodder"
          } else if (this.dialogTitle == '编辑') {
            apiUrl = "/chickFodder/editChickFodder"
          }
          let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
          if (ruleForm.propType != 4) {
            ruleForm.money = ''
            ruleForm.doorSill = ''
            ruleForm.couponsType = ''
          }
          this.$http.post(apiUrl, ruleForm).then(({ data: result }) => {
            this.$message({
              message: this.dialogTitle + '成功',
              type: 'success'
            })
            this.getTableData()
            this.butLoading = false
            this.dialogFormVisible = false
          }).catch(err => {
            this.butLoading = false
          })
        }
      })
    },
    //删除
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/chickFodder/removeChickFodderById', { id: row.id }).then(res => {
          this.getTableData()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
      })
    },
    //重置清除校验
    resetForm () {
      Object.assign(
        this.$data.ruleForm,
        this.$options.data().ruleForm
      )
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
  },
}
</script>

<style lang="less" scoped>
</style>