<template>
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-jb">
				<div class="flex flex-ac flex-w">
					<!-- 					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20">系列名称：</span>
						<el-input class="w-250" v-model="retrieveForm.seriesName" clearable></el-input>
					</div>
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20">是否结束：</span>
						<el-select class="w-250" v-model="retrieveForm.isGq" clearable placeholder="请选择">
							<el-option label="正执行" :value="0">
							</el-option>
							<el-option label="已结束" :value="1">
							</el-option>
						</el-select>
					</div>
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20">是否上架：</span>
						<el-select class="w-250" v-model="retrieveForm.shelves" clearable placeholder="请选择">
							<el-option v-for="item in shelves" :key="item.value" :label="item.key" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div>
						<el-button type="primary" @click="submitSearch">查询</el-button>
					</div> -->
				</div>
				<div class="flex flex-ac">
					<div class="fs-16 fc-6b9eff pointer" @click="addList">
						<i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
					</div>
				</div>
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			<!-- 表格 -->
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="activityColumns" height="100%"
				:border="false" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
				<template v-slot:detailImg="scope">
					<img :src="scope.row.detailImg" alt="" class="smallImg" v-if="scope.row.detailImg">
				</template>
			</EleTable>
			<!-- 分页 -->
			<PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
		</TableHeightFull>
		<!-- 新增弹框 -->
		<help-activity-details ref="help-activity-details" @refresh="getTableData"></help-activity-details>
		<el-dialog title="中奖信息" :visible.sync="dialogUseVisible">
			<div class="dialog-content">
				<EleTable ref="recordRef" slot="record" :tableData="luckUserData" :columns="luckColumns" height="100%"
					v-loading="recLoading">
					<template v-slot:userImg="scope">
						<img :src="scope.row.userImg" alt="" class="smallImg" v-if="scope.row.userImg">
					</template>
				</EleTable>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogUseVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogUseVisible = false">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import indexMixin from "@/utils/indexMixin"
	import axios from "axios"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'helpActivity',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
			"help-activity-details": (resolve) => require(["@/views/modules/activity/zeroActDetail"], resolve),
		},
		computed: {
			...mapState('menu', {
				'shelves': state => state.dictList ? state.dictList.shelves : [], //是否上下架
			})
		},
		data() {
			return {
				dialogUseVisible: false,
				tabLoading: false,
				butLoading: false,
				recLoading: false,
				//活动列表
				activityColumns: [{
						type: 'index',
						label: '序号',
						align: 'center',
						index: this.indexMethod
					}, {
						label: '商品名称',
						prop: 'detailName',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						columnType: 'custom',
						label: '封面图',
						prop: 'detailImg',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						label: '参与人数',
						prop: 'actyNum',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						label: '是否已开奖',
						prop: 'isDrawn',
						align: 'left',
						'show-overflow-tooltip': true,
						formatData: (e) => {
							return e == true ? '已开奖' : '未开奖'
						}
					},
					{
						label: '中奖人数',
						prop: 'luckNum',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						label: '开始时间',
						prop: 'startTime',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						label: '结束时间',
						prop: 'endTime',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						label: '兑换截至时间',
						prop: 'exEndTime',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						columnType: 'button',
						label: '操作',
						align: 'right',
						fixed: 'right',
						width: '180',
						buttons: [{
							type: 'text',
							text: '查看中奖',
							fn: this.findList,
							hidden: (e) => {
								return e.isDrawn
							}
						}, {
							type: 'text',
							text: '编辑',
							fn: this.editList,
							hidden: (e) => {
								return !e.isDrawn
							}
						}, {
							type: 'text',
							text: '删除',
							fn: this.deleteList,
						}, ],
					},
				],
				luckColumns: [{
					label: '用户名',
					prop: 'userName',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					columnType: 'custom',
					label: '头像',
					prop: 'userImg',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '签号',
					prop: 'luckNumber',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '是否已领取',
					prop: 'isRcv',
					align: 'left',
					'show-overflow-tooltip': true,
					formatData: (e) => {
						return e == true ? '是' : '否'
					}
				}, {
					columnType: 'button',
					label: '操作',
					align: 'right',
					fixed: 'right',
					width: '180',
					buttons: [{
						type: 'text',
						text: '发放',
						fn: this.sendGoods,
						hidden: (e) => {
							return !e.isRcv
						}
					}],
				}],
				tableData: [],
				luckUserData: [],
				//弹框
				dialogTitle: '新增',
				dialogFormVisible: false,
				ruleForm: {
					//助力活动
					helpActyName: '',
					helpCoverImg: '',
					energy: '',
					seriesId: '',
					startDate: '',
					endDate: '',
					drawNum: '',
					//助力区间
					startArea: '',
					endArea: '',
					newEnergy: '',
					oldEnergy: '',
				},
				seriesList: [], //系列列表
			}
		},
		mounted() {
			// this.helpTitle = '助力活动'
			this.getTableData()
			// this.findBoxSeries()
			// this.testSave()
		},
		methods: {
			//搜索
			submitSearch() {
				this.getTableData()
			},
			//获取表格数据
			getTableData() {
				this.$http.get('/freeDrawActy/findFreeDrawActyList', {
					params: {
						currentPage: this.pageInfo.pageNum,
						pageSize: this.pageInfo.pageSize,
						// seriesName: this.retrieveForm.seriesName,
						// isGq: this.retrieveForm.isGq,
						// shelves: this.retrieveForm.shelves,
					}
				}).then(({
					data: result
				}) => {
					this.tabLoading = false
					this.tableData = result.data.list
					this.pageInfo.total = result.data.total
				}).catch((err) => {})
			},
			//删除
			deleteList(row) {
				this.$confirm('您确认删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/freeDrawActy/removeFreeDrawActyById', {
						id: row.id
					}).then(({
						data: result
					}) => {
						this.getTableData()
						this.$message({
							message: '删除成功',
							type: 'success'
						})
					})
				}).catch(_ => {})
			},
			//新增列表
			addList() {
				this.$refs['help-activity-details'].init()
			},
			//查看活动的中签号码
			findList(row) {
				this.dialogUseVisible = true
				this.recLoading = true
				this.$http.get('/freeDrawActy/findLuckUserList', {
					params: {
						actyId: row.id
					}
				}).then(({
					data: result
				}) => {
					this.recLoading = false
					this.luckUserData = result.data
				}).catch((err) => {
					this.recLoading = false
				})
			},
			sendGoods(row){
				this.$confirm('您确认给该用户发放奖励吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/freeDrawActy/sendRewardsByActyUserId', {
						actyUserId: row.id
					}).then(({
						data: result
					}) => {
						this.$message({
							message: '发放成功',
							type: 'success'
						})
						this.dialogUseVisible = false
					})
				}).catch(_ => {})
			},
			//跳转详情
			editList(row) {
				// this.dialogFormVisible = true
				this.$refs['help-activity-details'].init(row.id)
			},
		},
	}
</script>
<style lang="less" scoped>
	.smallImg {
		width: 40px;
		height: 40px;
		border-radius: 5px;
	}
</style>