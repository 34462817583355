<template>
	<!-- 预售订单 -->
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-jb">
				<div>
					<el-button type="primary" @click="ShowSearchClick">检索</el-button>
				</div>
				<div>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="isShowSearch" title="检索列表" size="80%" :with-header="false">
			<div class="retrieveDiv flex flex-ac flex-w">
				<div class="mr-30 flex flex-ac">
					<span class="width-100 fs-16 flex1 mr-20">用户名：</span>
					<el-input class="w-250" v-model="retrieveForm.userName" clearable></el-input>
				</div>
				<div class="mr-30 flex flex-ac">
					<span class="width-100 fs-16 flex1 mr-20">手机号：</span>
					<el-input class="w-250" v-model="retrieveForm.phone" clearable></el-input>
				</div>
				<div class="mr-30 mt-20 flex flex-ac">
					<span class="width-100 fs-16 flex1 mr-20">系列名称：</span>
					<el-input class="w-250" v-model="retrieveForm.seriesName" clearable></el-input>
				</div>
				<div class="mr-30 mt-20 flex flex-ac">
					<span class="width-100 fs-16 flex1 mr-20">商户单号：</span>
					<el-input class="w-250" v-model="retrieveForm.outTradNo" clearable></el-input>
				</div>
				<div class="mr-30 mt-20 flex flex-ac">
					<span class="width-100 fs-16 flex1 mr-20">支付类型：</span>
					<el-select class="w-250" v-model="retrieveForm.accountType" clearable placeholder="请选择">
						<el-option v-for="item,index in accountTypes" :key="index" :label="item.key" :value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="mr-30 mt-20 flex flex-ac">
				<span class="width-100 fs-16 flex1 mr-20">创建时间：</span>
				<el-date-picker class="w-300" v-model="startEndDate" type="datetimerange" valueFormat="yyyy-MM-dd"
					range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="handPickerChange">
				</el-date-picker>
			</div>
			<div class="mt-20 flex-1" style="text-align: right;">
				<el-button type="primary" @click="submitSearch">查询</el-button>
			</div>
		</el-dialog>
		<TableHeightFull class="tableDiv pall-10">
			<!-- 表格 -->
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
				stripe v-loading="loading" :row-style="{ cursor: 'pointer' }">
				<template v-slot:userImg="scope">
					<img :src="scope.row.userImg" alt="" class="smallImg" v-if="scope.row.userImg">
				</template>
			</EleTable>
			<div slot='payTotal'>
				<span class="" style="font-size: 16px;font-weight: bold;">微信支付总数: {{accountData.wxMoney}}</span>
				<span class="ml-30" style="font-size: 16px;font-weight: bold;">虚拟币总数:{{accountData.useBlance}}</span>
			</div>
			<!-- 分页 -->
			<PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableList" />
		</TableHeightFull>
	</div>
</template>
<script>
	import indexMixin from "@/utils/indexMixin"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'drawsGood',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
			"CountDown": (resolve) => require(["@/components/CountDown"], resolve),
		},
		computed: {
			...mapState('menu', {
				'buyTypes': state => state.dictList ? state.dictList.buy_type : [], //商品类型
				'accountTypes': state => state.dictList ? state.dictList.account_type : [], //支付类型
			})
		},
		data() {
			return {
				retrieveForm: {
					outTradNo: '', //商户号
					userName: '', //用户名称
					phone: '', //用户手机号
					seriesName:'',//系列名称
					accountType: '', //支付类型
					startDate: '', //开始时间
					endDate: '', //	结束时间
				},
				startEndDate: [], //时间区间
				loading: false,
				isShowSearch: false,
				columns: [{
						type: 'index',
						label: '序号',
						width: '60',
						align: 'center',
						fixed: 'left',
						index: this.indexMethod
					}, {
						columnType: 'custom',
						label: '头像',
						prop: 'userImg',
						align: 'left',
						width: '80'
					}, {
						label: '用户名',
						prop: 'userName',
						align: 'left',
						'show-overflow-tooltip': true,
						width: '150'
					}, {
						label: '手机号',
						prop: 'phone',
						align: 'left',
						width: '120'
					}, {
						label: '系列名称',
						prop: 'seriesName',
						align: 'left',
						width: '180'
					}, {
						label: '商户单号',
						prop: 'outTradNo',
						align: 'left',
						width: '180'
					}, {
						label: '金额(人民币)',
						prop: 'money',
						align: 'center',
						width: '120'
					}, {
						label: '金额(充值币)',
						prop: 'blance',
						align: 'center',
						width: '120'
					}, {
						columnType: 'fun',
						label: '支付类型',
						prop: 'payType',
						align: 'left',
						width: '120',
						formatData: (e) => {
							let ele = this.buyTypes.find(ele => ele.value == e)
							return ele ? ele.key : e
						}
					},
					// { label: '收货人', prop: 'recManName', align: 'left', 'show-overflow-tooltip': true },
					// { label: '收货手机号', prop: 'recManPhone', align: 'left', width: '120' },
					{
						label: '创建时间',
						prop: 'createDate',
						align: 'left',
						width: '160',
					}, {
						columnType: 'button',
						label: '操作',
						align: 'right',
						fixed: 'right',
						width: '80',
						buttons: [{
							type: 'text',
							text: '退币',
							fn: this.freightRefund,
							hidden: (e) => {
								return (e.freighType == 1 || e.freighType == 3)
							}
						}, ],
					},
				],
				tableData: [],
				accountData:'',
			}
		},
		mounted() {
			this.getTableList()
		},
		methods: {
			//查询
			submitSearch() {
				this.pageInfo.pageNum = 1
				this.isShowSearch = false
				this.getTableList()
			},
			//是否显示检索功能
			ShowSearchClick() {
				this.isShowSearch = !this.isShowSearch
			},
			//时间变化
			handPickerChange(value) {
				if (value && value.length != 0) {
					this.retrieveForm.startDate = value[0]
					this.retrieveForm.endDate = value[1]
				} else {
					this.retrieveForm.startDate = ""
					this.retrieveForm.endDate = ""
				}
			},
			//获取表格数据
			getTableList() {
				this.loading = true
				this.$http.get('/appletUser/findAccountList', {
					params: {
						currentPage: this.pageInfo.pageNum,
						pageSize: this.pageInfo.pageSize,
						...this.retrieveForm
					}
				}).then(({
					data: result
				}) => {
					this.loading = false
					this.accountData = result.data
					this.tableData = result.data.list
					this.pageInfo.total = result.data.total
				})
				//邮费老接口
				// this.$http.get('/tradingOrder/findFreightRecordList', {
				//   params: {
				//     currentPage: this.pageInfo.pageNum,
				//     pageSize: this.pageInfo.pageSize,
				//     ...this.retrieveForm
				//   }
				// }).then(({ data: result }) => {
				//   this.loading = false
				//   this.tableData = result.data.list
				//   this.pageInfo.total = result.data.total
				// })
			},
			//回收
			freightRefund(row) {
				this.$confirm('您确认回退虚拟币？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.get('/tradingOrder/freightRefund', {
						params: {
							id: row.id
						}
					}).then(({
						data: result
					}) => {
						this.$message({
							message: '回退成功',
							type: 'success'
						})
						this.getTableList()
					})
				}).catch(_ => {})
			},
		},
	}
</script>
<style lang="less" scoped>
	::v-deep .gray-row {
		background: #e0e0e0 !important;
	}

	.width-100 {
		width: 100px;
		text-align: right;
	}
</style>