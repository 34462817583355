<template>
	<el-drawer title="助力活动详情" :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
		:modal-append-to-body="false" :wrapperClosable="false" :close-on-press-escape="false">
		<div class="h-full flex flex-dc" >
			<div class="drawer_title pall-30 flex flex-jb flex-n">
				<div class="fs-18 fw-bold">助力活动详情</div>
				<i class="el-icon-close fs-20" @click="handleClose"></i>
			</div>
			<div class="flex-1 overflow-scroll-y pall-30">
				<el-form :inline="true" ref="formActivity" :model="formActivity" :rules="rules"
					class="demo-form-inline">
					<el-form-item label="商品名称" prop="detailName">
						<el-input v-model="formActivity.detailName" type="text" placeholder="系列名称"></el-input>
					</el-form-item>
					<el-form-item label="参与人数" prop="actyNum">
						<el-input v-model="formActivity.actyNum" placeholder="参与人数"></el-input>
					</el-form-item>
					<el-form-item label="中奖人数" prop="luckNum">
						<el-input v-model="formActivity.luckNum" placeholder="中奖人数"></el-input>
					</el-form-item>
					<el-form-item label="开始时间" prop="startTime">
						<el-date-picker v-model="formActivity.startTime" valueFormat="yyyy-MM-dd HH:mm:ss"
							type="datetime" placeholder="开始日期时间" @change="startChangDate">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="结束时间" prop="endTime">
						<el-date-picker v-model="formActivity.endTime" valueFormat="yyyy-MM-dd HH:mm:ss" type="datetime"
							placeholder="结束日期" @change="endChangDate">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="兑换结束日期" prop="exEndTime">
						<el-date-picker v-model="formActivity.exEndTime" valueFormat="yyyy-MM-dd HH:mm:ss" type="datetime"
							placeholder="兑换结束日期" @change="exChangDate">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="封面图" prop="coverImg">
						<upload-file
							upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
							iconName="el-icon-upload" iconSize="16" v-model="formActivity.coverImg">
						</upload-file>
					</el-form-item>
					<el-form-item label="详情图" prop="detailImg">
						<upload-file
							upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
							iconName="el-icon-upload" iconSize="16" v-model="formActivity.detailImg">
						</upload-file>
					</el-form-item>
				</el-form>
			</div>
			<div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
				<el-button type="primary" @click="submitForm">{{goodId?'保存':'提交'}}</el-button>
				<el-button type="primary" plain @click="handleClose">取消</el-button>
			</div>
		</div>
	</el-drawer>
</template>
<script>
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'helpActivityDetails',
		components: {
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
		},
		computed: {
			...mapState('menu', {
				'propTypes': state => state.dictList ? state.dictList.prop_type : [], //道具类型
				'couponsTypes': state => state.dictList ? state.dictList.coupons_type : [],  //优惠劵类型
			})
		},
		data() {
			return {
				isDrawer: false,
				seriesList: [], //系列列表
				goodId: '', //详情id
				butLoading: false,
				formActivity: {
					detailName:'',
					detailImg:'',
					coverImg:'',
					luckNum:'',
					actyNum:'',
					startTime: '',
					id:'',//系列id
					endTime: '',
					exEndTime:'',
				},
				rules: {
					detailName: {
						required: true,
						message: '请输入商品名称',
						trigger: 'blur'
					},
					startTime: {
						required: true,
						message: '请输入开始时间',
						trigger: 'change'
					},
					endTime: {
						required: true,
						message: '请输入结束时间',
						trigger: 'change'
					},
					exEndTime: {
						required: true,
						message: '请输入兑换结束时间',
						trigger: 'change'
					},
					actyNum: {
						required: true,
						message: '请输入参与人数',
						trigger: 'blur'
					},
					luckNum: {
						required: true,
						message: '请输入中奖人数',
						trigger: 'blur'
					},
					detailImg: {
						required: true,
						message: '请上传图片',
						trigger: 'blur'
					},
					coverImg: {
						required: true,
						message: '请上传图片',
						trigger: 'blur'
					},
				},
			}
		},
		
		mounted() {
			this.findBoxSeries()
		},
		methods: {
			init(id) {
				this.isDrawer = true
				if (id) {
					this.goodId = id
					this.getDetails(id)
				}
			},
			changeValue(val){
				console.log(val,"changeValue")
			},
			//查询所有抽盒系列
			findBoxSeries() {
				this.$http.get('/boxSeries/findBoxSeries').then(({
					data: result
				}) => {
					this.seriesList = result.data
				}).catch(err => {})
			},
			//获取详情数据
			getDetails(id) {
				this.$http.get(`/freeDrawActy/findFreeDrawActyById?id=${id}`).then(({
					data: res
				}) => {
					let goodData = res.data
					// if (goodData.detailsList == null || goodData.detailsList == undefined || goodData
					// 	.detailsList == "") {
					// 	goodData.detailsList = []
					// }
					this.formActivity = goodData
					console.log(goodData, "goodData")
				})
			},
			//关闭弹框
			handleClose() {
				this.isDrawer = false
				this.resetForm()
			},
			//开始时间处理
			startChangDate(val) {
				console.log(val, "val")
				if (val) {
					this.formActivity.startDate = val
				} else {
					this.formActivity.startDate = ''
				}
			},
			//结束时间处理
			endChangDate(val) {
				if (val) {
					this.formActivity.endDate = val
				} else {
					this.formActivity.endDate = ''
				}
			},
			exChangDate(){
				if (val) {
					this.formActivity.exEndTime = val
				} else {
					this.formActivity.exEndTime = ''
				}
			},
			//编辑
			editOkSubmit() {
				let goodData = this.formActivity
				let apiUrl = ''
				if (this.goodId) { //修改
					apiUrl = '/freeDrawActy/editFreeDrawActy'
				}
				 else { //新增
					apiUrl = '/freeDrawActy/addFreeDrawActy'
				}
				this.$http.post(apiUrl, goodData).then(res => {
					this.$message({
						message: this.goodId ? '修改成功' : '新增成功',
						type: 'success'
					})
					this.$emit('refresh')
					this.handleClose()
				}).catch(err => {})
			},
			//提交
			submitForm() {
				this.$refs['formActivity'].validate((valid) => {
					if (valid) {
						if (!valid) return
						console.log("最终数据", this.formActivity)
						this.editOkSubmit()
					}
				})
			},
			// 重置form表单
			resetForm() {
				Object.assign(this.$data.formActivity, this.$options.data.call(this).formActivity)
				console.log(this.$refs.formActivity, "this.$refs.formActivity")
				this.$nextTick(() => {
					this.$refs.formActivity.clearValidate()
				})
			},
			// 重置form表单
			// resetForm() {
			// 	Object.assign(this.$data.formActivity, this.$options.data.call(this).formActivity)
			// 	this.$refs.formActivity.clearValidate()
			// },
		}
	}
</script>
<style lang="less" scoped>
	.ranking_but {
		border: 1px dashed #409eff;
		border-radius: 20px;
		color: #409eff;
	}
</style>
