<template>
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-jb">
				<div class="flex flex-ac">
					<div class="fs-16 fc-6b9eff pointer" @click="addList">
						<el-button type="primary">新增</el-button>
					</div>
					<!-- <div class="ml-40 fc-666 fs-16 pointer" @click="getArea" v-if="helpTitle == '助力活动'">助力区间</div> -->
					<!-- <div class="ml-40 fc-666 fs-16 pointer" @click="returnActivity" v-if="helpTitle == '助力区间'">返回</div> -->
				</div>
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			<!-- 表格 -->
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="activityColumns" height="100%"
				:border="false" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
				<template v-slot:areaImg="scope">
					<img :src="scope.row.areaImg" alt="" class="smallImg" v-if="scope.row.areaImg">
				</template>
			</EleTable>
			<!-- 分页 -->
		</TableHeightFull>
		<!-- 新增弹框 -->
		<ChickenDetail ref="ChickenDetail" @refresh="getTableData"></ChickenDetail>
	</div>
</template>
<script>
	import indexMixin from "@/utils/indexMixin"
	import axios from "axios"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'helpActivity',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"ChickenDetail": (resolve) => require(["@/views/modules/activity/ChickenDetail"], resolve),
		},
		computed: {
			...mapState('menu', {
				'shelves': state => state.dictList ? state.dictList.shelves : [], //是否上下架
			}),
			isMax(){
				this.tableData.filter(item=>{
					return item.isMaxArea == false ? '否':'是'
				})
			}
		},
		data() {
			return {
				helpTitle: '助力活动',
				tabLoading: false,
				butLoading: false,
				// ranks: [{
				// 		actyId: 1,
				// 		actyType: 1001,
				// 		id: 1,
				// 		passingLine: 2,
				// 		props: [{
				// 			actyRankId: 1
				// 		}, {
				// 			couponsType: 1
				// 		}, ]
				// 	}, {
				// 		actyId: 2,
				// 		actyType: 1002,
				// 		id: 2,
				// 		passingLine: 3,
				// 		props: [{
				// 			actyRankId: 1
				// 		}, {
				// 			couponsType: 1
				// 		}, ]
				// 	}
				// 	// ... 其他 rank 和 props 的组合
				// ],
				//活动列表
				activityColumns: [{
					type: 'index',
					label: '序号',
					// width: '60',
					align: 'center',
					index: this.indexMethod
				}, {
					columnType: 'custom',
					label: '服装图片',
					prop: 'areaImg',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '区间名称',
					prop: 'areaName',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '开始值',
					prop: 'areaStartNum',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '结束值',
					// width:'80',
					prop: 'areaEndNum',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '是否为最大区间',
					prop: 'isMaxArea',
					align: 'left',
					'show-overflow-tooltip': false,
					formatData: (e) => {
						let ele = this.tableData.find(ele => ele.isMaxArea == e)
						return ele.isMaxArea == true ? '是' : '否'
					}
				}, {
					columnType: 'button',
					label: '操作',
					align: 'right',
					fixed: 'right',
					width: '150',
					buttons: [{
						type: 'text',
						text: '编辑',
						fn: this.editList,
					}, {
						type: 'text',
						text: '删除',
						fn: this.deleteList,
					}, ],
				}, ],
				tableData: [],
				//弹框
				dialogTitle: '新增',
				dialogFormVisible: false,
				seriesList: [], //系列列表
			}
		},
		mounted() {
			// this.helpTitle = '助力活动'
			this.getTableData()
			// this.findBoxSeries()
			// this.testSave()
		},
		methods: {
			//助力区间
			getArea() {
				this.helpTitle = '助力区间'
				this.getTableData()
			},
			//返回
			returnActivity() {
				this.helpTitle = '助力活动'
				this.getTableData()
			},
			//获取表格数据
			getTableData() {
				this.$http.get('/chickArea/findChickAreaList').then(({
					data: result
				}) => {
					this.tabLoading = false
					this.tableData = result.data
					this.pageInfo.total = result.data.total
				}).catch((err) => {})
			},
			//删除
			deleteList(row) {
				this.$confirm('您确认删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/chickArea/removeChickAreaById', {
						id: row.id
					}).then(({
						data: result
					}) => {
						this.getTableData()
						this.$message({
							message: '删除成功',
							type: 'success'
						})
					})
				}).catch(_ => {})
			},
			//新增列表
			addList() {
				this.$refs['ChickenDetail'].init()
			},
			//跳转详情
			editList(row) {
				// this.dialogFormVisible = true
				this.$refs['ChickenDetail'].init(row.id)
			},
		},
	}
</script>
<style lang="less" scoped>
</style>