<template>
	<!-- 现货订单 -->
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-ac flex-jb">
				<div class="mr-30 flex flex-ac">
					<div class="mr-30">
						<span class="fs-16 flex1 mr-20">类型：</span>
						<el-select class="w-250" v-model="boxType" @change="selectChange" clearable placeholder="请选择">
							<el-option v-for="item in boxTypes" :key="item.value" :label="item.key" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="mr-30">
						<span class="fs-16 flex1 mr-20">商品：</span>
						<el-select v-model="seriesGoods" clearable>
							<el-option v-for="item in showData" :label="item.seriesName" :value="item.seriesId" :key="item.seriesId">
							</el-option>
						</el-select>
					</div>
					<div>
						<el-button type="primary" @click="ShowSearchClick">检索</el-button>
					</div>
				</div>
				<div class="flex flex-ac">
					<div class="ml-40 fc-6b9eff fs-16 pointer" @click="exportExcel">
						<i class="el-icon-upload mr-10 fs-18"></i>导出
					</div>
				</div>
			</div>
		</div>
		<!-- 撤销弹窗 -->
		<el-dialog title="商品信息" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
			<el-table ref="multipleTable" :data="shujuList" tooltip-effect="dark" style="width: 100%"
				:header-cell-style="{background:'#ecf5ff'}" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column type="index" width="55" lable='序号'>
				</el-table-column>
				<!-- <el-table-column label="发货单号" width="120" prop="sendNo">
			</el-table-column> -->
				<el-table-column label="头像">
					<template slot-scope="scope">
						<img :src="scope.row.userImg" width="40" height="40" />
					</template>
				</el-table-column>
				<el-table-column prop="userName" label="用户名" width="100" show-overflow-tooltip>
				</el-table-column>
				<el-table-column label="商品信息" width="100">
					<template slot-scope="scope">
						<div>{{scope.row.seriesName}}---{{scope.row.detailsName}}</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="recManAddr" label="收货地址" width="120">
			</el-table-column>
			<el-table-column prop="address" label="收货人" show-overflow-tooltip>
			</el-table-column> -->
				<el-table-column prop="outTradeNo" label="商品单号" show-overflow-tooltip width="200">
				</el-table-column>
				<el-table-column prop="price" label="价格" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="createDate" label="提交时间" show-overflow-tooltip width="200">
				</el-table-column>
				<!-- <el-table-column fixed="right" label="操作" width="120">
				<template slot-scope="scope">
					<el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">
						移除
					</el-button>
				</template>
			</el-table-column> -->
			</el-table>
			<div class="button">
				<!-- <el-button type="primary" @click="chehuiSold(1)" plain class="button1">撤回预售</el-button> -->
				<el-button type="primary" @click="chehuiSold(2)" plain class="button1">撤回现货</el-button>
				<el-button type="primary" @click="chehuiSold(3)" plain class="button1">撤回待发货</el-button>
			</div>
			<!-- <span slot="footer" class="dialog-footer">
			<el-button @click="dialogVisible = false">取 消</el-button>
			<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
		</span> -->
		</el-dialog>
		<!-- 删除弹窗 -->
		<el-dialog title="删除" :visible.sync="delectVisible" width="50%" :before-close="handleClose">
			<el-table ref="multipleTable" :data="shujuList" tooltip-effect="dark" style="width: 100%"
				:header-cell-style="{background:'#ecf5ff'}" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column type="index" width="55" lable='序号'>
				</el-table-column>
				<!-- <el-table-column label="发货单号" width="120" prop="sendNo">
			</el-table-column> -->
				<el-table-column label="头像">
					<template slot-scope="scope">
						<img :src="scope.row.userImg" width="40" height="40" />
					</template>
				</el-table-column>
				<el-table-column prop="userName" label="用户名" width="100" show-overflow-tooltip>
				</el-table-column>
				<el-table-column label="商品信息" width="100">
					<template slot-scope="scope">
						<div>{{scope.row.seriesName}}---{{scope.row.detailsName}}</div>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="recManAddr" label="收货地址" width="120">
			</el-table-column>
			<el-table-column prop="address" label="收货人" show-overflow-tooltip>
			</el-table-column> -->
				<el-table-column prop="outTradeNo" label="商品单号" show-overflow-tooltip width="200">
				</el-table-column>
				<el-table-column prop="price" label="价格" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="createDate" label="提交时间" show-overflow-tooltip width="200">
				</el-table-column>
				<!-- <el-table-column fixed="right" label="操作" width="120">
				<template slot-scope="scope">
					<el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">
						移除
					</el-button>
				</template>
			</el-table-column> -->
			</el-table>
			<div class="button">
				<el-button type="primary" @click="delectClick" plain class="button1">删除</el-button>
			</div>
			<!-- <span slot="footer" class="dialog-footer">
			<el-button @click="dialogVisible = false">取 消</el-button>
			<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
		</span> -->
		</el-dialog>
		<!-- <TableHeightFull class="tableDiv pall-10"> -->
		<!-- 表格 -->
		<div class="tableDiv pall-10">
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="700" :border="false"
				stripe v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
				<template v-slot:img="scope">
					<img :src="scope.row.userImg" alt="" class="smallImg">
				</template>
				<template v-slot:serImg="scope">
					<img :src="scope.row.seriesImg" alt="" class="smallImg">
				</template>
			</EleTable>
		</div>
		<!-- 分页 -->
		<!-- <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableList" /> -->
		<!-- </TableHeightFull> -->
		<!-- 打印模板 -->
		<vue-easy-print tableShow ref="easyPrint" v-show="false">
			<template slot-scope="func">
				<print-template :printInnerHTML="printInnerHTML"></print-template>
			</template>
		</vue-easy-print>
	</div>
</template>
<script>
	import indexMixin from "@/utils/indexMixin"
	import printTemplate from "@/components/printTemplate.vue"
	import vueEasyPrint from "vue-easy-print"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'drawsGood',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
			"CountDown": (resolve) => require(["@/components/CountDown"], resolve),
			printTemplate,
			vueEasyPrint
		},
		computed: {
			...mapState('menu', {
				'goodsTypes': state => state.dictList ? state.dictList.goods_type : [], //商品类型
				'boxTypes': state => state.dictList ? state.dictList.box_type : [], //优惠劵类型
			})
		},
		data() {
			return {
				dialogVisible: false, //控制撤销弹窗
				delectVisible: false, //控制删除弹窗
				printInnerHTML: '', //打印链接
				startEndDate: [], //时间区间
				tabLoading: false,
				butLoading: false,
				columns: [{
					type: 'index',
					label: '序号',
					width: '60',
					align: 'center',
					fixed: 'left',
					index: this.indexMethod
				}, {
					label: '发货单号',
					prop: 'sendNo',
					align: 'left',
				}, {
					columnType: 'custom',
					label: '头像',
					prop: 'img',
					align: 'left',
					width: '80'
				}, {
					label: '用户名',
					prop: 'userName',
					align: 'left',
					width: '100'
				}, {
					label: '快递单号',
					prop: 'expressageNo',
					align: 'left',
				}, {
					label: '收货人',
					prop: 'recManName',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '商品名称',
					prop: 'detailsName',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					columnType: 'custom',
					label: '商品图片',
					prop: 'serImg',
					align: 'left',
					width: '80',
					'show-overflow-tooltip': true
				}, {
					label: '价格',
					prop: 'seriesPrice',
					align: 'left',
					width: '80',
					'show-overflow-tooltip': true
				}, {
					label: '发货时间',
					prop: 'updateDate',
					align: 'left',
				}],
				tableData: [],
				shujuList: [],
				isShowSearch: false,
				showData: '',
				seriesGoods: '',
				boxType: ''
			}
		},
		mounted() {
			this.getTableList()
		},
		methods: {
			//查询
			submitSearch() {
				this.pageInfo.pageNum = 1
				this.isShowSearch = false
				this.getTableList()
			},
			//是否显示检索功能
			ShowSearchClick() {
				this.getTableList()
			},
			//导出
			exportExcel() {
				if (!this.boxType && !this.seriesGoods) return
				this.$http.post('/showDataSeries/exportOrderShowData', {
					goodsType: this.boxType,
					seriesId: this.seriesGoods
				}, {
					responseType: "blob", // 1.首先设置responseType对象格式为 blob:
				}).then((result) => {
					let blob = new Blob([result.data], {
						type: "application/vnd.ms-excel;charset=utf-8",
					})
					// 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
					let url = window.URL.createObjectURL(blob) // 3.创建一个临时的url指向blob对象
					// 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
					let a = document.createElement("a")
					a.href = url
					a.download = "已发货订单.xls"
					a.click()
					// 5.释放这个临时的对象url
					window.URL.revokeObjectURL(url)
				}).catch((err) => {})
			},
			//时间变化
			selectChange(e) {
				if (e) {
					this.seriesGoods = ''
					this.findShowDataList(e)
				}
			},
			//获取表格数据
			findShowDataList(type) {
				this.loading = true
				this.$http.get('/showDataSeries/findShowDataList', {
					params: {
						goodsType: type
					}
				}).then(({
					data: result
				}) => {
					this.loading = false
					this.showData = result.data
				})
			},
			getTableList() {
				this.tabLoading = true
				this.$http.get('/showDataSeries/findOrderShowDataList', {
					params: {
						// goodsType: 5,
						// seriesId: 380
						goodsType: this.boxType,
						seriesId: this.seriesGoods
					}
				}).then(({
					data: result
				}) => {
					this.tabLoading = false
					this.tableData = result.data
				}).catch(err => {
					this.tabLoading = false
				})
			},
			//撤回
			recallList(row) {
				console.log(row)
				this.dialogVisible = true
				this.shujuList = row.list
				this.sendNum = row.sendNo
			},
			//删除
			deleteList(row) {
				this.delectVisible = true
				this.shujuList = row.list
				this.sendNum = row.sendNo
			},
			// 多选
			handleSelectionChange(val) {
				if (val) {
					let idList = val.map(ele => {
						return ele.id
					})
					this.orderIds = idList.join(',')
				}
			},
			// 撤回到预售
			chehuiSold(Type) {
				this.$http.post('/tradingOrder/cancelMultipleDelivery', {
					orderIds: this.orderIds,
					sendNo: this.sendNum,
					tradingType: Type
				}).then(res => {
					this.getTableList()
					this.dialogVisible = false
				}).catch(err => {
					if (err) {
						this.$confirm('请选择数据', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						})
					}
				})
			},
			//删除
			delectClick() {
				this.$confirm('您确认删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/tradingOrder/removeTradingOrder', {
						id: this.orderIds,
					}).then(({
						data: result
					}) => {
						this.$message({
							message: '删除成功',
							type: 'success'
						})
						this.delectVisible = false
						this.getTableList()
					})
				}).catch(_ => {})
			},
			// 关闭弹窗
			handleClose(done) {
				done()
			},
			//跳转详情
			headerRowClick(row) {},
			//发货
			doublePrint(row) {
				this.$confirm('您确认打印？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/tradingOrder/repeatPrint', {
						sendNo: row.sendNo,
						type: 0, //0是取消发货1是撤销发货
						userId: row.userId
					}).then((result) => {
						if (result.data.data) {
							// window.document.body.innerHTML = result.data.data
							// window.print()
							// window.open(result.data.data)
							this.printInnerHTML = result.data.data
							setTimeout(() => {
								this.$refs.easyPrint.print()
							}, 100)
						} else {
							this.$message({
								message: '打印成功',
								type: 'success'
							})
						}
					}).catch((err) => {})
				})
			},
			//取消
			// recallList (row) {
			//   this.$confirm('您确认撤销？', '提示', {
			//     confirmButtonText: '确定',
			//     cancelButtonText: '取消',
			//     type: 'warning'
			//   }).then(_ => {
			//     this.$http.post('/tradingOrder/cancelDelivery', {
			//       sendNo: row.sendNo,
			//       type: 0,  //0是取消发货1是撤销发货
			//       userId: row.userId
			//     }).then((result) => {
			//       this.getTableList()
			//       this.$message({
			//         message: '撤销成功',
			//         type: 'success'
			//       })
			//     }).catch((err) => { })
			//   })
			// },
			//商品类型
			formatGoodType(val) {
				if (val) {
					let goodsData = this.goodsTypes.find(ele => ele.value == val)
					if (goodsData) {
						return goodsData.key
					} else {
						return ''
					}
				} else {
					return ''
				}
			},
		},
	}
</script>
<style lang="less" scoped>
	::v-deep .gray-row {
		background: #e0e0e0 !important;
	}

	.button {
		display: flex;
		justify-content: center;
		margin-top: 22px;

		.button1 {
			width: 20%;
			font-size: 0.1rem;
			font-weight: 600;
		}
	}

	.search-width {
		width: 45%;
	}

	.width-100 {
		width: 120px;
		text-align: right;
	}
</style>