<template>
	<el-drawer title="一番赏详情" :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
		:modal-append-to-body="false" :wrapperClosable="false" :close-on-press-escape="false">
		<div class="h-full flex flex-dc" v-if="isDrawer">
			<div class="drawer_title pall-30 flex flex-jb flex-n">
				<div class="fs-18 fw-bold">一番赏详情</div>
				<i class="el-icon-close fs-20 pointer" @click="handleClose"></i>
			</div>
			<div class="pl-40 flex-1 overflow-scroll-y">
				<t-simple-form :ref-obj.sync="formOpts.ref" :formOpts="formOpts" :widthSize="4" @handleEvent="handleEvent"
					class="drawer_form">
					<!-- 平台自定义插槽 -->
					<template #goodDetailsList>
						<div class="pos-s t-0 bgc-fff zi-10">
							<div class="pb-20 flex flex-ac flex-jb">
								<div class="fs-14 fw-bold flex-1">
									<span class="h-10 w-5 bgc-38393b mr-10 dis-in-block"></span>
									<span>系列商品</span>
								</div>
								<div class="flex flex-ac">
									<div class="mr-30">数量：{{formOpts.formData.goodDetailsList.length}}</div>
									<el-button type="primary" @click="addDomain">新增</el-button>
								</div>
							</div>
						</div>
						<div v-for="(domains,index) in formOpts.formData.goodDetailsList" class="flex flex-ac mb-20" :key="index">
							<el-checkbox v-model="domains.isPresell" :true-label="1" :false-label="2">是否预售</el-checkbox>
							<el-checkbox v-model="domains.isNewUserConsume" true-label="true" false-label="false">开启消费</el-checkbox>
							<el-form-item :label="'商品名称'" label-position="right" label-width="80px"
								:prop="'goodDetailsList.' + index + '.ductName'"
								:rules="{required: true, message: '商品名称不能为空', trigger: 'blur'}" class="flex flex-ac">
								<el-input v-model="domains.ductName" :style="{width: '280px'}" type="text" clearable></el-input>
							</el-form-item>
							<el-form-item label="商品等级" label-position="right" label-width="90px"
								:prop="'goodDetailsList.' + index + '.gradeConsumeId'"
								:rules="{required: true, message: '请至少选择一个商品等级', trigger: 'blur'}" class="flex flex-ac"
								v-if="!testFlag">
								<el-select v-model="domains.gradeConsumeId" :style="{width: '100px'}" clearable placeholder="请选择">
									<el-option :key="12" label="终赏" :value="12" v-if="domains.levelType == 12"></el-option>
									<el-option v-for="item in gradeConsumes" :key="item.id" :label="item.grade" :value="item.id" v-else>
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="库存数量" label-position="right" label-width="90px"
								:prop="'goodDetailsList.' + index + '.inventory'"
								:rules="{required: true, message: '库存不能为空', trigger: 'blur'}" class="flex flex-ac">
								<el-input v-model="domains.inventory" :style="{width: '100px'}" type="number" clearable></el-input>
							</el-form-item>
							<el-form-item label="参考价格" label-position="right" label-width="90px"
								:prop="'goodDetailsList.' + index + '.referPrice'"
								:rules="{required: true, message: '参考价格不能为空', trigger: 'blur'}" class="flex flex-ac">
								<el-input v-model="domains.referPrice" type="number" :style="{width: '100px'}" clearable></el-input>
							</el-form-item>
							<el-form-item label="参考等级" label-position="right" label-width="90px"
								:prop="'goodDetailsList.' + index + '.levelType'"
								:rules="{required: true, message: '请至少选择一个参考等级', trigger: 'change'}" class="flex flex-ac">
								<el-select v-model="domains.levelType" :style="{width: '80px'}" clearable placeholder="请选择">
									<el-option :key="12" label="LAST" :value="12" v-if="domains.gradeConsumeId == 12"></el-option>
									<el-option v-for="item in levelTypes" :key="item.value" :label="item.key" :value="item.value" v-else>
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="商品图片" label-position="right" label-width="90px"
								:prop="'goodDetailsList.' + index + '.boxImg'"
								:rules="{required: true, message: '图片不能为空', trigger: 'blur'}" class="flex flex-ac">
								<upload-file upStyle="width: 50px; height: 50px; border-radius: 5px; overflow: hidden;"
									iconName="el-icon-upload" iconSize="16" v-model="domains.boxImg"></upload-file>
							</el-form-item>
							<el-button type="primary" plain @click.prevent="removeDomain(index)" class="ml-20"
								v-if="formOpts.formData.goodDetailsList.length > 1">删除 </el-button>
						</div>
					</template>
					<!-- 上传图片 -->
					<template #uploadFile="data">
						<upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
							iconName="el-icon-upload" iconSize="26" v-model="formOpts.formData[data.value]"></upload-file>
					</template>
				</t-simple-form>
			</div>
			<div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
				<el-button type="primary" @click="submitForm" :loading="butLoading">提交检测</el-button>
				<el-button type="primary" plain @click="handleClose">取消</el-button>
			</div>
		</div>
		<!--    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" append-to-body>
      <span>您确认展示{{formOpts.formData.showPage}}套吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="visibleDe">确 定</el-button>
      </span>
    </el-dialog> -->
		<!-- 显示最终大于5的商品信息 -->
		<el-dialog title="商品信息" :visible.sync="dialogInventory" width="50%" append-to-body :before-close="inventoryClose">
			<div style="font-size: 20px;margin-bottom: 20px;font-weight: bold;">共有预售商品<span
					style="color: red;">{{isPressList.length}}</span>个</div>
			<div :style="{fontWeight:'bold',fontSize:'20px',marginBottom:'30px' }">您确认展示<span
					style="color: red;">{{formOpts.formData.showPage}}</span>套吗？
			</div>
			<el-table ref="multipleTable" :data="shujuList" tooltip-effect="dark" style="width: 100%"
				:header-cell-style="{background:'#ecf5ff'}">
				<el-table-column type="index" lable='序号'>
				</el-table-column>
				<el-table-column prop="ductName" label="商品信息" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="inventory" label="库存数量" show-overflow-tooltip>
					<template slot-scope="scope">
						<!-- 使用 formatter 属性自定义列的显示样式 -->
						<span :style="{ color: 'red',fontWeight:'bold',fontSize:'20px' }">{{ scope.row.inventory }}</span>
					</template>
				</el-table-column>
			</el-table>
			<div class="button">
				<!-- <el-button type="primary" @click="chehuiSold(1)" plain class="button1">撤回到预售</el-button> -->
				<el-button type="primary" @click="editOkSubmit" plain class="button1">{{goodId?'确认保存':'确认新增'}}</el-button>
			</div>
		</el-dialog>
	</el-drawer>
</template>
<script>
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'rewardDetails',
		components: {
			"TSimpleForm": (resolve) => require(["@/components/public/TSimpleForm"], resolve),
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
		},
		computed: {
			...mapState('menu', {
				'levelTypes': state => state.dictList ? state.dictList.level_type : [], //系列分类
			})
		},
		//  mounted () {
		// // // seriesPattern   formOpts formData
		// if(this.formOpts.listTypeInfo.sericeTypeOptions[0].value == 1){
		// 	this.isSeriesType = true
		// } else {
		// 	this.isSeriesType = false
		// }
		//  },
		data() {
			return {
				dialogInventory: false, //确认系列数量
				goodId: '', //详情id
				isDrawer: false, //详情弹框
				isSeriesType: false, //控制增加秒数的时间显示
				isOffDate: false,
				butLoading: false,
				shujuList: [],
				isPressList: '',
				formOpts: {
					labelPosition: 'top',
					ref: null,
					formData: {
						seriesName: '', //系列名称
						goodSeriesType: '', //系列分类
						shelves: '', //是否上架
						price: '', //原价
						soldPrice: '', //售出价格
						boxNum: '', //盒子数量
						deliverDate: '', //发货日期
						totalPage: '3', //总的套数
						showPage: '3', //展示的套数
						// minSoldMsg: '100',
						money: '10', //回收优惠金余额
						dateDay: '365', //有效期
						recycleBlance: '0', //回收金额
						seriesPattern: '', //系列状态
						coverImg: '', //封面图
						entireImg: '', //全家福图片
						detailImg: '', //详情图
						id: '', //系列id
						note: '重点必看：“概率公示”和“玩法说明”抽前必读，当有用户发起全收时，其它用户进入排队页面，该用户1分钟内没有付款，则自动关闭，其它用户恢复正常购买，绝版/限定商品可能是拆盒拆袋发出介意勿拍！大娃，吊卡标注的预售时间为预估发货时间，实际都以官方到货时间为准! 不处理官方瑕疵，请您根据自身使用把玩需求进行购买，售出不退不换，不包官瑕，介意勿拍！未成年人禁止下单！', //玩法说明
						sort: '', //排序
						buySec: 20,
						consume: '',
						consumeOffDate: '',
						exchOff: 0,
						exchEndDate: '',
						goodMustOff: true,
						goodDetailsList: [{
							ductName: '', //商品名称
							gradeConsumeId: '', //实际等级
							referPrice: '', //参考价格(前端显示)
							inventory: '', //	库存
							levelType: 12, //前端展示等级
							boxImg: '', //商品图片
							isPresell: 2, //是否预售  1预售  2现货
							isNewUserConsume: false
						}], //商品信息
					},
					fieldList: [{
						label: '系列名称',
						value: 'seriesName',
						comp: 'el-input'
					}, {
						label: '分类',
						value: 'goodSeriesType',
						type: 'select-arr',
						comp: 'el-select',
						list: 'goodSeriesTypes',
						bind: {
							disabled: false
						},
						arrLabel: 'key',
						arrKey: 'value'
					}, {
						label: '上下架售罄',
						value: 'shelves',
						type: 'select-arr',
						comp: 'el-select',
						list: 'shelves',
						arrLabel: 'key',
						arrKey: 'value'
					}, {
						label: '发货日期',
						value: 'deliverDate',
						type: 'date',
						comp: 'el-date-picker',
						bind: {
							format: "yyyy-MM-dd",
							valueFormat: "yyyy-MM-dd"
						}
					}, {
						label: '原价',
						value: 'price',
						type: 'number',
						comp: 'el-input',
					}, {
						label: '售出价格',
						value: 'soldPrice',
						type: 'number',
						comp: 'el-input',
						event: 'showPrice'
					}, {
						label: '系列状态',
						value: 'seriesPattern',
						type: 'select-arr',
						comp: 'el-select',
						list: 'sericeTypeOptions',
						arrLabel: 'key',
						arrKey: 'value',
						event: 'seriesPattern'
					}, {
						label: '回收芒币',
						value: 'recycleBlance',
						type: 'number',
						comp: 'el-input',
						isHideItem: () => {
							return !this.testFlag
						}
					}, {
						label: '回收优惠金',
						value: 'money',
						type: 'number',
						comp: 'el-input',
						event: 'showCounp',
						isHideItem: () => {
							return !this.testFlag
						}
					}, {
						label: '有效期',
						value: 'dateDay',
						type: 'number',
						comp: 'el-input',
						isHideItem: () => {
							return !this.testFlag
						}
					}, {
						label: '总的套数',
						value: 'totalPage',
						type: 'number',
						comp: 'el-input',
						bind: {
							disabled: true
						},
					}, {
						label: '展示的套数',
						value: 'showPage',
						type: 'number',
						comp: 'el-input',
						event: 'showPage'
					}, {
						label: '累计消费限制',
						value: 'consume',
						type: 'number',
						comp: 'el-input',
						event: 'consume'
					}, {
						label: '开放日期',
						value: 'consumeOffDate',
						type: 'date',
						comp: 'el-date-picker',
						bind: {
							format: "yyyy-MM-dd",
							valueFormat: "yyyy-MM-dd"
						},
						isHideItem: () => {
							return this.isOffDate
						}
					}, {
						label: '排序',
						value: 'sort',
						type: 'number',
						comp: 'el-input'
					}, {
						label: '限时兑换',
						value: 'exchOff',
						comp: 'el-switch',
						event: 'exchOff',
						bind: {
							activeValue: 1,
							inactiveValue: 0
						}
					}, {
						label: '限时结束日期',
						value: 'exchEndDate',
						type: 'datetime',
						comp: 'el-date-picker',
						bind: {
							format: "yyyy-MM-dd HH:mm:ss",
							valueFormat: "yyyy-MM-dd HH:mm:ss"
						},
						isHideItem: () => {
							return this.isExch
						}
					}, {
						label: '保底开关',
						value: 'goodMustOff',
						comp: 'el-switch',
						bind: {
							activeValue: true,
							inactiveValue: false
						}
					}, {
						label: '增加时间（s）',
						value: 'buySec',
						type: 'number',
						comp: 'el-input',
						isHideItem: () => {
							return this.isSeriesType
						}
					}, {
						label: '玩法说明',
						value: 'note',
						type: 'textarea',
						comp: 'el-input',
						className: 't-form-block'
					}, {
						label: '封面图',
						value: 'coverImg',
						slotName: 'uploadFile'
					}, {
						label: '详情图',
						value: 'detailImg',
						slotName: 'uploadFile'
					}, {
						label: '全家福图片',
						value: 'entireImg',
						slotName: 'uploadFile'
					}, {
						label: '',
						slotName: 'goodDetailsList',
						className: 't-form-block'
					}, ],
					rules: {
						seriesName: [{
							required: true,
							message: '请输入系列名称',
							trigger: 'blur'
						}],
						shelves: [{
							required: true,
							message: '请至少选择一个状态',
							trigger: 'change'
						}],
						seriesPattern: [{
							required: true,
							message: '请至少选择一个系列状态',
							trigger: 'change'
						}],
						goodSeriesType: [{
							required: true,
							message: '请至少选择一个分类',
							trigger: 'change'
						}],
						deliverDate: [{
							required: true,
							message: '请选择发货日期',
							trigger: 'blur'
						}],
						price: [{
							required: true,
							message: '请输入价格',
							trigger: 'blur'
						}],
						soldPrice: [{
							required: true,
							message: '请输入现价',
							trigger: 'blur'
						}],
						totalPage: [{
							required: true,
							message: '请输入总套数',
							trigger: 'blur'
						}],
						showPage: [{
							required: true,
							message: '请输入展示的套数',
							trigger: 'blur'
						}],
						money: [{
							required: true,
							message: '请输入回收优惠金额度',
							trigger: 'blur'
						}],
						dateDay: [{
							required: true,
							message: '请输入优惠金有效天数',
							trigger: 'blur'
						}],
						recycleBlance: [{
							required: true,
							message: '请输入回收芒币数量',
							trigger: 'blur'
						}],
						consumeOffDate: [{
							required: true,
							message: '请选择开放日期',
							trigger: 'blur'
						}],
						exchEndDate: [{
							required: true,
							message: '请选择限时结束日期',
							trigger: 'blur'
						}]
					},
					//按钮
					operatorList: [],
					// 相关列表
					listTypeInfo: {
						goodSeriesTypes: this.$store.state.menu.dictList.good_series_type,
						shelves: this.$store.state.menu.dictList.shelves,
						sericeTypeOptions: [{
							value: 1,
							key: '排队'
						}, {
							value: 0,
							key: '竞技'
						}],
					}
				},
				gradeConsumes: [], //真实等级
				testFlag: false, //测试
			}
		},
		created() {
			//测试人员
			// let userInfo = JSON.parse(localStorage.getItem('userInfo'))
			// if (userInfo.name == '测试') {
			//   this.testFlag = true
			// }
		},
		mounted() {},
		methods: {
			// 初始化
			init(id, type) {
				var that = this
				that.findGrade()
				that.isDrawer = true
				that.isSeriesType = false
				that.isOffDate = false
				if (type) {
					this.formOpts.formData.sort = type[0].sort + 1
				}
				if (id) {
					that.goodId = id
					that.getDetails(id)
				} else {
					that.goodId = ''
					this.formOpts.formData.recycleBlance = Number(this.formOpts.formData.money) * 0.8
				}
			},
			//一番赏商品真实等级
			findGrade() {
				this.$http.get('/grade/findGrade').then(({
					data: result
				}) => {
					this.gradeConsumes = result.data
				})
			},
			//关闭弹框
			handleClose() {
				this.butLoading = false
				this.isDrawer = false
				this.resetForm()
			},
			inventoryClose() {
				this.butLoading = false
				this.dialogInventory = false
			},
			//获取详情数据
			getDetails(id) {
				this.$http.get(`/goodSeries/findGoodSeriesById?id=${id}`).then(({
					data: res
				}) => {
					let goodData = res.data
					if (goodData.goodDetailsList == null || goodData.goodDetailsList == undefined || goodData
						.goodDetailsList == "") {
						goodData.goodDetailsList = []
					}
					this.isSeriesType = goodData.seriesPattern == 1 ? true : false
					this.isOffDate = goodData.consume > 0 ? true : false
					this.isExch = goodData.exchOff == 0 ? false : true
					this.formOpts.formData = goodData
				})
			},
			// 触发事件
			handleEvent(type, val) {
				switch (type) {
					case 'checkbox':
						break
					case 'showPage':
						if (!this.goodId) {
							this.formOpts.formData.totalPage = val
						}
						break
					case 'seriesPattern':
						if (val == 1) {
							this.isSeriesType = true
							this.formOpts.formData.buySec = 20
						} else {
							this.isSeriesType = false
							this.formOpts.formData.buySec = 0
						}
						break
					case 'showPrice':
						this.formOpts.formData.price = Number(this.formOpts.formData.soldPrice) + 10
						break
					case 'showCounp':
						this.formOpts.formData.recycleBlance = Number(this.formOpts.formData.money) * 0.8
						break
					case 'consume':
						this.isOffDate = this.formOpts.formData.consume > 0 ? true : false
						break
					case 'exchOff':
						this.isExch = this.formOpts.formData.exchOff == 0 ? false : true
						break
				}
			},
			// 新增商品
			addDomain() {
				let list = this.formOpts.formData.goodDetailsList
				let levelType = 12
				if (list.length == 1) {
					levelType = 17
				} else if (list.length > 1) {
					levelType = list[list.length - 1].levelType + 1
				}
				this.formOpts.formData.goodDetailsList.push({
					ductName: '', //商品名称
					gradeConsumeId: '', //实际等级
					referPrice: '', //参考价格(前端显示)
					inventory: '', //	库存
					levelType: levelType, //前端展示等级
					boxImg: '', //商品图片
					isPresell: 2, //是否预售  1预售  2现货
				})
			},
			removeDomain(index) {
				this.formOpts.formData.goodDetailsList.splice(index, 1)
			},
			//提示后最终提交表单
			editOkSubmit() {
				this.dialogInventory = false
				let goodData = JSON.parse(JSON.stringify(this.formOpts.formData))
				goodData.json = JSON.stringify(goodData.goodDetailsList)
				delete goodData.goodDetailsList
				let apiUrl = ''
				if (this.goodId) { //修改
					apiUrl = '/goodSeries/editGoodSeries'
				} else { //新增
					// apiUrl = '/goodSeries/saveGoodSeries'
					apiUrl = '/goodSeries/saveGoodSeries'
				}
				this.$http.post(apiUrl, goodData).then(res => {
					this.$message({
						message: goodData.id ? '修改成功' : '新增成功',
						type: 'success'
					})
					this.butLoading = false
					this.$emit('refresh')
					this.handleClose()
				}).catch(err => {
					this.butLoading = false
				})
			},
			// //控制弹窗显示
			// visibleDe(){
			// 	this.dialogInventory = true
			// },
			// 提交form表单
			submitForm() {
				this.formOpts.ref.validate((valid) => {
					if (valid) {
						this.butLoading = true
						if (!valid) return
						let inventory = this.formOpts.formData.goodDetailsList.filter(ele => ele.inventory > 5 || ele.inventory <
							1)
							let isPressList = this.formOpts.formData.goodDetailsList.filter(ele => ele.isPresell == 1)
						let soldPricePer = this.formOpts.formData.soldPrice * 0.6
						if (this.formOpts.formData.money > soldPricePer) {
							this.$message({
								message: '优惠金额超出限制',
								type: 'error'
							})
							this.butLoading = false
						} else if (this.formOpts.formData.recycleBlance > soldPricePer) {
							this.$message({
								message: '芒币数量超出限制',
								type: 'error'
							})
							this.butLoading = false
						} else {
							this.isPressList = isPressList
							this.shujuList = inventory
							this.dialogInventory = true
						}
						// if (this.formOpts.formData.showPage > 5) {
						// }
						// else {
						// this.editOkSubmit()
						// }
					}
				})
			},
			// 重置form表单
			resetForm() {
				this.$data.formOpts.formData = this.$options.data.call(this).formOpts.formData
				this.$nextTick(() => {
					this.formOpts.ref.clearValidate()
				})
			},
		}
	}
</script>
<style lang="less" scoped>
	.button {
		display: flex;
		justify-content: center;
		margin-top: 22px;

		.button1 {
			width: 20%;
			font-size: 0.1rem;
			font-weight: 600;
		}
	}
</style>