<template>
	<el-drawer title="风云榜奖励" :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
		:modal-append-to-body="false" :wrapperClosable="false" :close-on-press-escape="false">
		<div class="h-full flex flex-dc" >
			<div class="drawer_title pall-30 flex flex-jb flex-n">
				<div class="fs-18 fw-bold">风云榜奖励</div>
				<i class="el-icon-close fs-20" @click="handleClose"></i>
			</div>
			<div class="flex-1 overflow-scroll-y pall-30">
				<el-form :inline="true" ref="formActivity" :model="formActivity" :rules="rules"
					class="demo-form-inline">
					<el-form-item label="系列名称" prop="helpActyName">
						<el-input v-model="formActivity.helpActyName" type="text" placeholder="系列名称"></el-input>
					</el-form-item>
					<el-form-item label="活动类型" prop="actyType">
						<el-select class="w-250" v-model="formActivity.actyType" clearable placeholder="请选择">
							<el-option v-for="(item,index) in actyTypes" :label="item.key" :value="item.value" :key="index"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="价格" prop="price">
						<el-input v-model="formActivity.price" type="number" placeholder="价格"></el-input>
					</el-form-item>
					<el-form-item label="是否上架" prop="shelves">
						<el-select class="w-250" v-model="formActivity.shelves" clearable placeholder="请选择">
							<el-option label="上架" :value="15">
							</el-option>
							<el-option label="下架" :value="16">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="开始时间" prop="startDate">
						<el-date-picker v-model="formActivity.startDate" valueFormat="yyyy-MM-dd HH:mm:ss"
							type="datetime" placeholder="开始日期时间" @change="startChangDate">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="结束时间" prop="endDate">
						<el-date-picker v-model="formActivity.endDate" valueFormat="yyyy-MM-dd HH:mm:ss" type="datetime"
							placeholder="结束日期" @change="endChangDate">
						</el-date-picker>
					</el-form-item>
					<!-- 					<el-form-item label="关联系列" prop="seriesId" v-if="'seriesId' in formActivity"
						:rules="{required: true, message: '请至少选择一个系列', trigger: 'change'}">
						<el-select v-model="formActivity.seriesId" placeholder="请选择">
							<el-option v-for="item in seriesList" :key="item.id" :label="item.seriesName"
								:value="item.id" v-if="item.value != 1"></el-option>
						</el-select>
					</el-form-item> -->
					<el-form-item label="领取人数" prop="drawNum" v-if="formActivity.actyType == 2">
						<el-input v-model="formActivity.drawNum" placeholder="领取人数"></el-input>
					</el-form-item>
					<el-form-item label="活动封面图" prop="helpCoverImg" v-if="formActivity.actyType == 2">
						<!-- <el-input v-model="scope.row.propImg" placeholder="道具图片"></el-input> -->
						<upload-file
							upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
							iconName="el-icon-upload" iconSize="16" v-model="formActivity.helpCoverImg">
						</upload-file>
					</el-form-item>
					<el-form-item label="活动详情图" prop="helpDetailImg" v-if="formActivity.actyType == 2">
						<!-- <el-input v-model="scope.row.propImg" placeholder="道具图片"></el-input> -->
						<upload-file
							upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
							iconName="el-icon-upload" iconSize="16" v-model="formActivity.helpDetailImg">
						</upload-file>
					</el-form-item>
					<el-card class="mb-30" v-for="(item,index) in formActivity.ranks" :key="index">
						<div slot="header" class="flex flex-ac flex-jb">
							<div>排名{{index + 1}}</div>
							<el-button style="float: right; padding: 3px 0" type="text" @click="delRanking(index,item)" 
							v-if="formActivity.ranks.length > 1">删除
							</el-button>
						</div>
						<el-form-item label="排名" :prop="'ranks.' + index + '.rankRange'" :rules="{required: true,message: '请输入排行区间',trigger: 'blur'}">
							<el-input v-model="item.rankRange" type="text" placeholder="请输入排名"></el-input>
						</el-form-item>
						<el-form-item label="最低能量值" v-if="formActivity.actyType == 2" :prop="'ranks.' + index + '.passingLine'" :rules="{required: true,message: '请输入排名网格线',trigger: 'blur'}">
							<el-input v-model="item.passingLine" type="number" :min="0" placeholder="最低能量值"></el-input>
						</el-form-item>
						<el-button style="float: right;" type="primary" @click="addProp(item)">新增</el-button>
						<el-table :data="item.props" border style="width: 100%">
							<el-table-column label="道具名称">
								<template slot-scope="scope">
									<el-form-item prop="propName" :prop="'ranks.' + index + '.props.' + scope.$index + '.propName'" 
									:rules="{required: true,message: '请输入道具名称',trigger: 'change'}">
										<el-input v-model="scope.row.propName" type="text" placeholder="道具名称"></el-input>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="道具类型">
								<template slot-scope="scope">
									<el-form-item  :prop="'ranks.' + index + '.props.' + scope.$index + '.propType'" 
									:rules="{required: true,message: '请输入道具类型',trigger: 'change'}">
										<el-select v-model="scope.row.propType" placeholder="请选择类型" clearable>
											<el-option v-for="(item,index) in propTypes" :label="item.key" :value="item.value" :key="index"></el-option>
										</el-select> 
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="道具图片" prop="propImg">
								<template slot-scope="scope">
									<el-form-item
									:prop="'ranks.' + index + '.props.' + scope.$index + '.propImg'"
									:rules="{required: true,message: '请上传道具图片',trigger: 'blur'}">
										<upload-file
											upStyle="width: 70px; height: 70px; border-radius: 5px; overflow: hidden;"
											iconName="el-icon-upload" iconSize="16" v-model="scope.row.propImg">
										</upload-file>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="数量" prop="num">
								<template slot-scope="scope">
									<el-form-item prop="num"
									:prop="'ranks.' + index + '.props.' + scope.$index + '.num'"
									:rules="{required: true,message: '请输入数量',trigger: 'blur'}"
									>
										<el-input v-model="scope.row.num" type="number" :min="0" placeholder="兑换数量"></el-input>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="有效天数" prop="validDay">
								<template slot-scope="scope">
									<el-form-item prop="validDay" v-if="scope.row.propType !== 1 && scope.row.propType !== 6"
									:prop="'ranks.' + index + '.props.' + scope.$index + '.validDay'"
									:rules="{required: true,message: '请输入有效天数',trigger: 'blur'}"
									>
										<el-input v-model="scope.row.validDay" type="number" :min="0" placeholder="有效天数">
										</el-input>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="面额" prop="money">
								<template slot-scope="scope">
									<el-form-item prop="money" v-if="scope.row.propType == 4">
										<el-input v-model="scope.row.money" type="number" :min="0" placeholder="面额"></el-input>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="门槛" prop="doorSill">
								<template slot-scope="scope" v-if="scope.row.propType == 4">
									<el-form-item prop="doorSill">
										<el-input v-model="scope.row.doorSill" type="number" :min="0" placeholder="门槛">
										</el-input>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column label="优惠劵类型" prop="couponsType">
								<template slot-scope="scope">
									<el-form-item prop="couponsType" v-if="scope.row.propType == 4">
										<el-select v-model="scope.row.couponsType" placeholder="优惠劵类型">
											<el-option v-for="(item,index) in couponsTypes" :label="item.key" :value="item.value" :key="index"></el-option>
										</el-select>
									</el-form-item>
								</template>
							</el-table-column>
							<el-table-column fixed="right" label="操作" width="100">
								<template slot-scope="scope">
									<el-form-item>
										<el-button @click="delProp(scope.$index,item.props)" type="text" size="small" 
										v-if="item.props.length > 1">删除
										</el-button>
									</el-form-item>
								</template>
							</el-table-column>
						</el-table>
					</el-card>
					<div class="ranking_but w-full fs-18 fw-bold ph-16 flex flex-ac flex-jc" @click="addRanking">新增排名
					</div>
				</el-form>
			</div>
			<div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
				<!-- {{goodId?'保存':'提交'}} :loading="butLoading"-->
				<el-button type="primary" @click="submitForm">{{goodId?'保存':'提交'}}</el-button>
				<el-button type="primary" plain @click="handleClose">取消</el-button>
			</div>
		</div>
	</el-drawer>
</template>
<script>
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'helpActivityDetails',
		components: {
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
		},
		computed: {
			...mapState('menu', {
				'propTypes': state => state.dictList ? state.dictList.prop_type : [], //道具类型
				'couponsTypes': state => state.dictList ? state.dictList.coupons_type : [],  //优惠劵类型
				'actyTypes': state => state.dictList ? state.dictList.acty_type : []  //活动类型
			})
		},
		data() {
			return {
				isDrawer: false,
				seriesList: [], //系列列表
				goodId: '', //详情id
				butLoading: false,
				formActivity: {
					drawNum: '0',
					helpActyName:'',
					price:'',
					helpCoverImg:'',
					helpDetailImg:'',
					startDate: '',
					id:'',//系列id
					endDate: '',
					actyType:'',//活动类型
					shelves:'',
					ranks: [{
						rankRange: '',
						passingLine: 0,
						props: [{
							propType: '',
							propName: '',
							validDay: '',
							money: '',
							doorSill: '',
							couponsType: '',
							num: '',
							propImg: '',
						}]
					}]
				},
				rules: {
					helpActyName: {
						required: true,
						message: '请输入活动',
						trigger: 'blur'
					},
					startDate: {
						required: true,
						message: '请输入开始时间',
						trigger: 'change'
					},
					endDate: {
						required: true,
						message: '请输入结束时间',
						trigger: 'change'
					},
					energy: {
						required: true,
						message: '请输入能量值',
						trigger: 'blur'
					},
					drawNum: {
						required: true,
						message: '请输入领取人数',
						trigger: 'blur'
					},
				},
			}
		},
		
		mounted() {
			this.findBoxSeries()
		},
		methods: {
			init(id) {
				this.isDrawer = true
				if (id) {
					this.goodId = id
					this.getDetails(id)
				}
			},
			changeValue(val){
				console.log(val,"changeValue")
			},
			//查询所有抽盒系列
			findBoxSeries() {
				this.$http.get('/boxSeries/findBoxSeries').then(({
					data: result
				}) => {
					this.seriesList = result.data
				}).catch(err => {})
			},
			//获取详情数据
			getDetails(id) {
				this.$http.get(`/helpActy/findRannPropByActyId?id=${id}`).then(({
					data: res
				}) => {
					let goodData = res.data
					// if (goodData.detailsList == null || goodData.detailsList == undefined || goodData
					// 	.detailsList == "") {
					// 	goodData.detailsList = []
					// }
					this.formActivity = goodData
					console.log(goodData, "goodData")
				})
			},
			//关闭弹框
			handleClose() {
				this.isDrawer = false
				this.resetForm()
			},
			//开始时间处理
			startChangDate(val) {
				console.log(val, "val")
				if (val) {
					this.formActivity.startDate = val
				} else {
					this.formActivity.startDate = ''
				}
			},
			//结束时间处理
			endChangDate(val) {
				if (val) {
					this.formActivity.endDate = val
				} else {
					this.formActivity.endDate = ''
				}
			},
			//新增排名
			addRanking() {
				console.log(this.formActivity.ranks,"this.formActivity.ranks")
				this.formActivity.ranks.push({
					rankRange: '',
					passingLine: 0,
					props: [{
						propType: '',
						propName: '',
						validDay: '',
						money: '',
						doorSill: '',
						couponsType: '',
						num: '',
						propImg: '',
					}]
				})
			},
			//删除排名
			delRanking(index,ite) {
				// console.log(index,ite,"index,ite")
				this.formActivity.ranks.splice(index, 1)
			},
			//新增道具
			addProp(ele) {
				ele.props.push({
					propType: '',
					propName: '',
					validDay: '',
					money: '',
					doorSill: '',
					couponsType: '',
					num: '',
					propImg: '',
				})
			},
			//删除道具
			delProp(inx, rows) {
				rows.splice(inx, 1)
			},
			//编辑
			editOkSubmit() {
				let goodData = JSON.stringify(this.formActivity)
				console.log(goodData,"this.formActivity")
				// goodData.json = JSON.stringify(goodData.detailsList)
				// delete goodData.detailsList
				// goodData.detailsList = JSON.stringify(goodData.detailsList)
				// console.log(goodData.detailsList, 'json')
				let apiUrl = ''
				if (this.goodId) { //修改
					apiUrl = '/helpActy/editActyAndRank'
				}
				 else { //新增
					apiUrl = '/helpActy/addActyAndRank'
				}
				this.$http.post(apiUrl, goodData, {
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(res => {
					this.$message({
						message: this.goodId ? '修改成功' : '新增成功',
						type: 'success'
					})
					this.$emit('refresh')
					this.handleClose()
				}).catch(err => {})
			},
			//提交
			submitForm() {
				this.$refs['formActivity'].validate((valid) => {
					if (valid) {
						if (!valid) return
						console.log("最终数据", this.formActivity)
						this.editOkSubmit()
					}
				})
			},
			// 重置form表单
			resetForm() {
				Object.assign(this.$data.formActivity, this.$options.data.call(this).formActivity)
				console.log(this.$refs.formActivity, "this.$refs.formActivity")
				this.$nextTick(() => {
					this.$refs.formActivity.clearValidate()
				})
			},
			// 重置form表单
			// resetForm() {
			// 	Object.assign(this.$data.formActivity, this.$options.data.call(this).formActivity)
			// 	this.$refs.formActivity.clearValidate()
			// },
		}
	}
</script>
<style lang="less" scoped>
	.ranking_but {
		border: 1px dashed #409eff;
		border-radius: 20px;
		color: #409eff;
	}
</style>
