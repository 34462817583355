<template>
	<!-- 现货订单 -->
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-jb">
				<div>
					<el-button type="primary" @click="ShowSearchClick">检索</el-button>
				</div>
				<div>
					<el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450"
						placeholder="请输入套数编码、系列名称、商品名称" v-model="retrieveForm.conditions">
						<el-button slot="append" type="primary" icon="el-icon-search" @click="submitSearch"></el-button>
					</el-input>
				</div>
				<div class="flex flex-ac">
					<div class="ml-40 fc-6b9eff fs-16 pointer" @click="exportExcel">
						<i class="el-icon-upload mr-10 fs-18"></i>导出
					</div>
				</div>
			</div>
		</div>
		<!-- 撤销弹窗 -->
		<el-dialog title="商品信息" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
			<el-table ref="multipleTable" :data="shujuList" tooltip-effect="dark" style="width: 100%"
				:header-cell-style="{background:'#ecf5ff'}" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column type="index" width="55" lable='序号'>
				</el-table-column>
				<el-table-column label="头像">
					<template slot-scope="scope">
						<img :src="scope.row.userImg" width="40" height="40" />
					</template>
				</el-table-column>
				<el-table-column prop="userName" label="用户名" width="100" show-overflow-tooltip>
				</el-table-column>
				<el-table-column label="商品信息" width="100">
					<template slot-scope="scope">
						<div>{{scope.row.seriesName}}---{{scope.row.detailsName}}</div>
					</template>
				</el-table-column>> <el-table-column prop="outTradeNo" label="商品单号" show-overflow-tooltip width="200">
				</el-table-column>
				<el-table-column prop="price" label="价格" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="createDate" label="提交时间" show-overflow-tooltip width="200">
				</el-table-column>
			</el-table>
			<div class="button">
				<!-- <el-button type="primary" @click="chehuiSold(1)" plain class="button1">撤回到预售</el-button> -->
				<el-button type="primary" @click="chehuiSold(2)" plain class="button1">撤回到现货</el-button>
			</div>
		</el-dialog>
		<!-- 删除弹窗 -->
		<el-dialog title="删除" :visible.sync="delectVisible" width="50%" :before-close="handleClose">
			<el-table ref="multipleTable" :data="shujuList" tooltip-effect="dark" style="width: 100%"
				:header-cell-style="{background:'#ecf5ff'}" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column type="index" width="55" lable='序号'>
				</el-table-column>
				<el-table-column label="头像">
					<template slot-scope="scope">
						<img :src="scope.row.userImg" width="40" height="40" />
					</template>
				</el-table-column>
				<el-table-column prop="userName" label="用户名" width="100" show-overflow-tooltip>
				</el-table-column>
				<el-table-column label="商品信息" width="100">
					<template slot-scope="scope">
						<div>{{scope.row.seriesName}}---{{scope.row.detailsName}}</div>
					</template>
				</el-table-column>> <el-table-column prop="outTradeNo" label="商品单号" show-overflow-tooltip width="200">
				</el-table-column>
				<el-table-column prop="price" label="价格" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="createDate" label="提交时间" show-overflow-tooltip width="200">
				</el-table-column>
			</el-table>
			<div class="button">
				<el-button type="primary" @click="delectClick" plain class="button1">删除</el-button>
			</div>
		</el-dialog>
		<!-- 检索列表 -->
		<el-dialog :visible.sync="isShowSearch" title="检索列表" size="80%" :with-header="false">
			<div class="retrieveDiv flex flex-ac flex-jb flex-w">
				<div class="search-width mr-30 flex flex-ac">
					<span class="width-100 fs-16 flex1 mr-20">收货人：</span>
					<el-input class="w-250" v-model="retrieveForm.recManName" clearable></el-input>
				</div>
				<div class="search-width mr-30 flex flex-ac">
					<span class="width-100 fs-16 flex1 mr-20">收货人手机号：</span>
					<el-input class="w-250" v-model="retrieveForm.recManPhone" clearable></el-input>
				</div>
				<div class="search-width mr-30 mt-20 flex flex-ac">
					<span class="width-100 fs-16 flex1 mr-20">发货单号：</span>
					<el-input class="w-250" v-model="retrieveForm.sendNo" clearable></el-input>
				</div>
				<div class="search-width mr-30 mt-20 flex flex-ac">
					<span class="width-100 fs-16 flex1 mr-20">商户单号：</span>
					<el-input class="w-250" v-model="retrieveForm.outTradeNo" clearable></el-input>
				</div>
				<div class="search-width mr-30 mt-20 flex flex-ac">
					<span class="width-100 fs-16 flex1 mr-20">用户名：</span>
					<el-input class="w-250" v-model="retrieveForm.userName" clearable></el-input>
				</div>
				<div class="search-width mr-30 mt-20 flex flex-ac">
					<span class="width-100 fs-16 flex1 mr-20">手机号：</span>
					<el-input class="w-250" v-model="retrieveForm.phone" clearable></el-input>
				</div>
				<div class="search-width mr-30 mt-20 flex flex-ac">
					<span class="width-100 fs-16 flex1 mr-20">排序：</span>
					<el-select class="w-150" v-model="retrieveForm.sort" clearable placeholder="请选择">
						<el-option label="正序" value="asc">
						</el-option>
						<el-option label="倒序" value="desc">
						</el-option>
					</el-select>
				</div>
				<div class="search-width mr-30 mt-20 flex flex-ac">
					<span class="width-100 fs-16 flex1 mr-20">人员类型：</span>
					<el-select class="w-150" v-model="retrieveForm.isTest" clearable placeholder="请选择">
						<el-option label="正式人员" :value="0">
						</el-option>
						<el-option label="测试人员" :value="1">
						</el-option>
					</el-select>
				</div>
				<div class="flex flex-ac flex-jb" style="width: 100%;">
					<div class="search-width mr-30 mt-20 flex flex-ac flex-1">
						<span class="width-100 fs-16 flex1 mr-20">提交时间：</span>
						<el-date-picker class="" v-model="startEndDate" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="handPickerChange">
						</el-date-picker>
					</div>
					<div class="mt-20">
						<el-button type="primary" @click="submitSearch">查询</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
		<TableHeightFull class="tableDiv pall-10">
			<!-- 表格 -->
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
				stripe @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
				<template v-slot:img="scope">
					<img :src="scope.row.userImg" alt="" class="smallImg">
				</template>
				<template v-slot:detailsList="scope">
					<div v-for="(item,index) in scope.row.list" :key="item.id">
						<div class="flex flex-ac">
							<div><span class="goods_type">[{{getKeyByValue(item.goodsType)}}]</span>{{item.seriesName}}</div>---- <div>{{item.detailsName}} </div>- <img :src="item.detailsImg" alt=""
								class="smallImg">- <div>{{item.price}}</div>
						</div>
						<div>商户单号：{{item.outTradeNo?item.outTradeNo:'无'}}</div>
					</div>
				</template>
			</EleTable>
			<!-- 分页 -->
			<PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableList" />
		</TableHeightFull>
		<el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="20vh" :width="dialogTitle=='发货'?'50%':'30%'">
			<div v-if="dialogTitle == '发货'">
				<t-simple-form :ref-obj.sync="formOpts.ref" :formOpts="formOpts" :widthSize="2" class="">
				</t-simple-form>
			</div>
			<div v-if="dialogTitle == '修改地址'">
				<el-form :model="ruleForm" ref="ruleForm" label-width="120px" class="demo-ruleForm">
					<el-form-item label="收货地址" prop="recManAddr" v-if="'recManAddr' in ruleForm"
						:rules="{required: true, message: '收货地址不能为空', trigger: 'blur'}">
						<el-input type="text" v-model="ruleForm.recManAddr"></el-input>
					</el-form-item>
					<el-form-item label="收货人" prop="recManName" v-if="'recManName' in ruleForm"
						:rules="{required: true, message: '收货人不能为空', trigger: 'blur'}">
						<el-input type="text" v-model="ruleForm.recManName"></el-input>
					</el-form-item>
					<el-form-item label="收货手机号" prop="recManPhone" v-if="'recManPhone' in ruleForm"
						:rules="{required: true, message: '收货手机号不能为空', trigger: 'blur'}">
						<el-input type="number" v-model="ruleForm.recManPhone"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" :loading="butLoading" @click="notarize">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 打印模板 -->
		<vue-easy-print tableShow ref="easyPrint" v-show="false">
			<template slot-scope="func">
				<print-template :printInnerHTML="printInnerHTML"></print-template>
			</template>
		</vue-easy-print>
	</div>
</template>
<script>
	import indexMixin from "@/utils/indexMixin"
	import printTemplate from "@/components/printTemplate.vue"
	import vueEasyPrint from "vue-easy-print"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'drawsGood',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
			"TSimpleForm": (resolve) => require(["@/components/public/TSimpleForm"], resolve),
			printTemplate,
			vueEasyPrint
		},
		computed: {
			...mapState('menu', {
				'goodsTypes': state => state.dictList ? state.dictList.goods_type : [], //商品类型
			})
		},
		data() {
			return {
				dialogVisible: false, //控制撤销弹窗
				delectVisible: false, //控制删除弹窗
				printInnerHTML: '', //打印链接
				orderIds: '', //撤销id
				sendNum: '', //撤销发货单号
				rulePayForm: '', //程序信息
				sendPhoneNum: '', //发货人手机号
				retrieveForm: {
					conditions: '', //整合条件
					// pageNo: '', //套数编码
					// detailsName: '', //商品名称,模糊匹配
					// seriesName: '', //系列名称,模糊匹配
					recManName: '', //收货人姓名
					recManPhone: '', //收货人手机号
					sendNo: '', //订单号
					outTradeNo: '', //商户单号
					userName: '', //用户名称 模糊匹配
					tradingType: 3, //商品状态
					sort: '', //排序desc  倒序 asc  升序
					phone: '', //用户手机号,模糊匹配
					isTest: 0, //是否测试
					startDate: '', //开始时间
					endDate: '', //	结束时间
				},
				startEndDate: [], //时间区间
				tabLoading: false,
				butLoading: false,
				columns: [{
					type: 'index',
					label: '序号',
					width: '60',
					align: 'center',
					fixed: 'left',
					index: this.indexMethod
				}, {
					label: '发货单号',
					prop: 'sendNo',
					align: 'left',
					width: '170'
				}, {
					columnType: 'custom',
					label: '头像',
					prop: 'img',
					align: 'left',
					width: '80'
				}, {
					label: '用户名',
					prop: 'userName',
					align: 'left',
					width: '100'
				}, {
					label: '收货地址',
					prop: 'recManAddr',
					align: 'left',
					width: '200'
				}, {
					label: '收货人',
					prop: 'recManName',
					align: 'left',
					width: '80',
					'show-overflow-tooltip': true
				}, {
					label: '收货手机号',
					prop: 'recManPhone',
					align: 'left',
					width: '120'
				}, {
					columnType: 'fun',
					label: '商品数量',
					prop: 'list',
					align: 'center',
					width: '80',
					formatData: (e) => {
						return e.length
					}
				}, {
					columnType: 'custom',
					label: '商品',
					prop: 'detailsList',
					align: 'left',
					width: '600'
				}, {
					label: '提交时间',
					prop: 'sendDate',
					align: 'left',
					width: '170',
				}, {
					columnType: 'button',
					label: '操作',
					align: 'right',
					fixed: 'right',
					width: '200',
					buttons: [{
						type: 'text',
						text: '发货',
						fn: this.deliverGoods,
					}, {
						type: 'text',
						text: '撤回',
						fn: this.recallList,
					}, {
						type: 'text',
						text: '删除',
						fn: this.deleteList,
					}, {
						type: 'text',
						text: '修改地址',
						fn: this.alterList,
					}],
				}, ],
				tableData: [],
				shujuList: [],
				//使用详情
				dialogTitle: '发货',
				dialogFormVisible: false,
				isShowSearch:false,
				ruleForm: [],
				formOpts: {
					labelPosition: 'top',
					ref: null,
					formData: {
						braesId: '', //网点信息的id
						printType: '', //打印方式
						cargo: '玩具', //包裹类型
						goodsSum: '', //商品数量
						recManAddr: '', //收货地址
						recManName: '', //	收货人
						recManPhone: '', //收货手机号
						sendAddr: '', //发货地址
						sendName: '', //发货人
						sendPhone: '', //发货人手机号
						sendNo: '', //统一发货 单号
						userId: '', //用户Id
						valinsPay: '', //保价额度
						expressageNo: '', //快递单号
					},
					fieldList: [{
						label: '网点信息',
						value: 'braesId',
						type: 'select-arr',
						comp: 'el-select',
						list: 'braesList',
						arrLabel: 'braesName',
						arrKey: 'id'
					}, {
						label: '打印方式',
						value: 'printType',
						type: 'select-arr',
						comp: 'el-select',
						list: 'printTypes',
						arrLabel: 'key',
						arrKey: 'value'
					}, {
						label: '包裹类型',
						value: 'cargo',
						type: 'text',
						comp: 'el-input',
					}, {
						label: '商品数量',
						value: 'goodsSum',
						type: 'number',
						comp: 'el-input'
					}, {
						label: '收货地址',
						value: 'recManAddr',
						type: 'text',
						comp: 'el-input'
					}, {
						label: '收货人',
						value: 'recManName',
						type: 'text',
						comp: 'el-input'
					}, {
						label: '收货手机号',
						value: 'recManPhone',
						type: 'number',
						comp: 'el-input'
					}, {
						label: '发货地址',
						value: 'sendAddr',
						type: 'text',
						comp: 'el-input',
						event: 'showPage'
					}, {
						label: '发货人',
						value: 'sendName',
						type: 'text',
						comp: 'el-input'
					}, {
						label: '发货人手机号',
						value: 'sendPhone',
						type: 'number',
						comp: 'el-input'
					}, {
						label: '保价额度',
						value: 'valinsPay',
						type: 'number',
						comp: 'el-input'
					}, {
						label: '快递单号',
						value: 'expressageNo',
						type: 'text',
						comp: 'el-input'
					}, ],
					rules: {
						braesId: [{
							required: true,
							message: '请选择网点信息',
							trigger: 'change'
						}],
						printType: [{
							required: true,
							message: '请选择打印方式',
							trigger: 'change'
						}],
						cargo: [{
							required: true,
							message: '包裹类型为空',
							trigger: 'blur'
						}],
						goodsSum: [{
							required: true,
							message: '商品数量不能为空',
							trigger: 'blur'
						}],
						recManAddr: [{
							required: true,
							message: '收货地址不能为空',
							trigger: 'blur'
						}],
						recManName: [{
							required: true,
							message: '收货人不能为空',
							trigger: 'blur'
						}],
						recManPhone: [{
							required: true,
							message: '收货手机号不能为空',
							trigger: 'blur'
						}],
						sendAddr: [{
							required: true,
							message: '发货地址不能为空',
							trigger: 'blur'
						}],
						sendName: [{
							required: true,
							message: '发货人不能为空',
							trigger: 'blur'
						}],
						sendPhone: [{
							required: true,
							message: '发货人手机号不能为空',
							trigger: 'blur'
						}]
					},
					// 相关列表
					listTypeInfo: {
						braesList: [], //网点信息
						printTypes: [{
							key: "云打印",
							value: 0
						}, {
							key: "本地打印",
							value: 1
						}, ],
					}
				},
			}
		},
		mounted() {
			this.getTableList()
			this.findCuryBraesList()
			this.findWxInFo()
		},
		methods: {
			//查询
			submitSearch() {
				this.pageInfo.pageNum = 1
				this.isShowSearch = false
				this.getTableList()
			},
			//是否显示检索功能
			ShowSearchClick(){
				this.isShowSearch = !this.isShowSearch
			},
			//导出
			exportExcel() {
				this.$http.post('/tradingOrder/exceportOrder', {
					...this.retrieveForm
				}, {
					responseType: "blob", // 1.首先设置responseType对象格式为 blob:
				}).then((result) => {
					let blob = new Blob([result.data], {
						type: "application/vnd.ms-excel",
					})
					// 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
					let url = window.URL.createObjectURL(blob) // 3.创建一个临时的url指向blob对象
					// 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
					let a = document.createElement("a")
					a.href = url
					a.download = "待发货订单.xls"
					a.click()
					// 5.释放这个临时的对象url
					window.URL.revokeObjectURL(url)
				}).catch((err) => {})
			},
			//时间变化
			handPickerChange(value) {
				debugger
				if (value && value.length != 0) {
					this.retrieveForm.startDate = value[0]
					this.retrieveForm.endDate = value[1]
				} else {
					this.retrieveForm.startDate = ""
					this.retrieveForm.endDate = ""
				}
			},
			// 关闭弹窗
			handleClose(done) {
				done()
			},
			//获取表格数据
			getTableList() {
				this.tabLoading = true
				this.$http.get('/tradingOrder/findDeliverOrderList', {
					params: {
						currentPage: this.pageInfo.pageNum,
						pageSize: this.pageInfo.pageSize,
						...this.retrieveForm
					}
				}).then(({
					data: result
				}) => {
					console.log("数据", result)
					this.tabLoading = false
					this.tableData = result.data.list
					this.pageInfo.total = result.data.total
				}).catch(err => {
					this.tabLoading = false
				})
			},
			//查询字典
			getKeyByValue(key) {
				const targetObj = this.goodsTypes.find(item => item.value === key);
				if (targetObj) {
					// 如果找到了符合条件的对象，则返回该对象的 key 
					if (key) {
						return targetObj.key
					}
				} else {
					// 如果数组中不存在符合条件的对象，则返回 null 表示空值
					return null;
				}
			},
			//查询网点
			findCuryBraesList() {
				this.$http.get('/cury/findCuryBraesList').then(({
					data: result
				}) => {
					this.formOpts.listTypeInfo.braesList = result.data
				})
			},
			//跳转详情
			headerRowClick(row) {},
			//查询程序配置
			findWxInFo() {
				this.$http.get('/wxInfo/findWxInfo').then((result) => {
					let WxIfo = result.data.data
					if (WxIfo.length > 0) {
						this.rulePayForm = WxIfo[1]
					}
				}).catch((err) => {})
			},
			//发货
			deliverGoods(row) {
				let list = this.formOpts.listTypeInfo.braesList
				debugger
				let rulePayForm = this.rulePayForm
				if (rulePayForm.appId == 'wxc436bbc67692be3e') {
					// 欧气酱
					this.sendPhoneNum = "13661517398"
				} else if (rulePayForm.appId == 'wx361e3192ec5d6829') {
					//抽盒鸭
					this.sendPhoneNum = "18917374423"
				} else if (rulePayForm.appId == 'wx5b9f5314fb2f6532') {
					//乐芒
					this.sendPhoneNum = "13221039003"
				}
				let ruleForm = {
					braesId: list.length > 0 ? list[0].id : '', //网点信息的id
					printType: 0, //打印方式
					cargo: '玩具', //包裹类型
					goodsSum: row.list.length, //商品数量
					recManAddr: row.recManAddr, //收货地址
					recManName: row.recManName, //	收货人
					recManPhone: row.recManPhone, //收货手机号
					sendAddr: list.length > 0 ? list[0].braesAddr : '', //发货地址
					sendName: window._config.logoTitle, //发货人
					sendPhone: this.sendPhoneNum, //发货人手机号
					sendNo: row.sendNo, //统一发货 单号
					userId: row.userId, //用户Id
					valinsPay: '', //保价额度
					expressageNo: '', //快递单号
				}
				this.dialogTitle = '发货'
				this.formOpts.formData = ruleForm
				this.dialogFormVisible = true
			},
			//撤回
			recallList(row) {
				this.dialogVisible = true
				this.shujuList = row.list
				this.sendNum = row.sendNo
			},
			//删除
			deleteList(row) {
				this.delectVisible = true
				this.shujuList = row.list
				this.sendNum = row.sendNo
			},
			// 多选
			handleSelectionChange(val) {
				console.log(val, "val")
				if (val) {
					let idList = val.map(ele => {
						return ele.id
					})
					this.orderIds = idList.join(',')
				}
			},
			// 撤回到预售
			chehuiSold(Type) {
				this.$http.post('/tradingOrder/cancelMultipleDelivery', {
					orderIds: this.orderIds,
					sendNo: this.sendNum,
					tradingType: Type
				}).then(res => {
					this.getTableList()
					this.dialogVisible = false
				}).catch(err => {
					if (err) {
						this.$confirm('请选择数据', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						})
					}
				})
			},
			//删除
			delectClick() {
				this.$confirm('您确认删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/tradingOrder/removeTradingOrder', {
						id: this.orderIds,
					}).then(({
						data: result
					}) => {
						this.$message({
							message: '删除成功',
							type: 'success'
						})
						this.delectVisible = false
						this.getTableList()
					})
				}).catch(_ => {})
			},
			//修改地址
			alterList(row) {
				let ruleForm = {
					recManAddr: row.recManAddr, //收货地址
					recManName: row.recManName, //	收货人
					recManPhone: row.recManPhone, //收货手机号
					sendNo: row.sendNo, //统一发货 单号
					userId: row.userId, //用户Id
				}
				this.ruleForm = ruleForm
				this.dialogTitle = '修改地址'
				this.dialogFormVisible = true
			},
			//确认弹框
			notarize() {
				let apiUrl = '',
					formData = '',
					dialogTitle = this.dialogTitle
				if (dialogTitle == '发货') {
					apiUrl = '/tradingOrder/sendOrderTool'
					formData = this.formOpts.formData
				} else if (dialogTitle == '修改地址') {
					apiUrl = '/tradingOrder/editRecMan'
					formData = this.ruleForm
				}
				this.butLoading = true
				this.$http.post(apiUrl, formData).then((result) => {
					this.dialogFormVisible = false
					this.butLoading = false
					this.getTableList()
					this.$message({
						message: dialogTitle + '成功',
						type: 'success'
					})
					if (formData.printType == 1) {
						if (result.data.data) {
							// window.open(result.data.data)
							this.printInnerHTML = result.data.data
							setTimeout(() => {
								this.$refs.easyPrint.print()
							}, 100)
						}
					}
				}).catch((err) => {
					this.butLoading = false
				})
			},
		},
	}
</script>
<style lang="less" scoped>
	::v-deep .gray-row {
		background: #e0e0e0 !important;
	}

	.button {
		display: flex;
		justify-content: center;
		margin-top: 22px;

		.button1 {
			width: 20%;
			font-size: 0.1rem;
			font-weight: 600;
		}
	}
	.goods_type {
		color: #AD92F4;
	}
	.search-width {
		width: 40%;
	}
	.width-100 {
		width: 120px;
		text-align: right;
	}
</style>