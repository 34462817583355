<template>
	<!-- 抽盒商品 -->
	<div class="rootDiv">
		<div class="retrieve">
			<div class="retrieveDiv flex flex-ac flex-jb" >
				<div class="flex flex-ac">
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20">商品名称：</span>
<!-- 						<el-select class="w-250" v-model="retrieveForm.seriesId" filterable clearable placeholder="请选择"
							@change="handleSeries">
							<el-option v-for="item in seriesList" :key="item.value" :label="item.seriesName" :value="item.id">
							</el-option>
						</el-select> -->
						<el-input  class="w-250" v-model="retrieveForm.detailName"></el-input>
					</div>
					<div class="mr-30 flex flex-ac">
						<span class="fs-16 flex1 mr-20">商品等级：</span>
						<el-select class="w-250" v-model="retrieveForm.levelId" filterable clearable placeholder="请选择"
							@change="handGoodsLevel">
							<el-option v-for="item in gradeConsumes" :key="item.id" :label="item.grade" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div>
					<el-button type="primary" @click="submitSearch" :disabled="retrieveForm.levelId && retrieveForm.detailName &&!selectData?false:true">执行</el-button>
					<el-button type="primary" @click="editSubmit"	:disabled="selectData?false:true">修改</el-button>
				</div>
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			<!-- 表格 -->
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
				v-loading="tabLoading" :row-style="{ cursor: 'pointer' }" @selection-change="handleSelectionChange">
				<template v-slot:img="scope">
					<img :src="scope.row.boxImg" alt="" class="smallImg" v-if="scope.row.boxImg">
				</template>
			</EleTable>
			<!-- 分页 -->
			<!-- <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" /> -->
		</TableHeightFull>
	</div>
</template>

<script>
	import indexMixin from "@/utils/indexMixin"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'draws',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
			"CountDown": (resolve) => require(["@/components/CountDown"], resolve),
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
		},
		computed: {
			...mapState('menu', {
				'boxTypes': state => state.dictList ? state.dictList.box_type : [], //优惠劵类型
			})
		},
		data() {
			return {
				retrieveForm: {
					// detailId: '',
					// seriesId: ''
					detailName:'',
					levelId:''
				},
				butLoading: false,
				tabLoading: false,
				columns: [{
						type: 'selection',
						align: 'center',
						width: "30",
						fixed: 'left'
					}, {
						type: 'index',
						label: '序号',
						width: '60',
						align: 'center',
						index: this.indexMethod
					},
					{
						label: '系列名称',
						prop: 'seriesName',
						align: 'left',
					},
					{
						columnType: 'custom',
						label: '商品图片',
						prop: 'img',
						align: 'left',
						width: '80'
					},
					{
						label: '商品名称',
						prop: 'ductName',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						label: '商品等级',
						prop: 'gradeConsumeId',
						align: 'left',
						'show-overflow-tooltip': true,
						formatData: (e) => {
							let ele = this.gradeConsumes.find(ele => ele.id == e)
							return ele ? ele.grade : ''
						}
					},
					{
						label: '平台',
						prop: 'sysName',
						align: 'left',
						'show-overflow-tooltip': true
					}
				],
				gradeConsumes:'',//一番赏真实等级
				tableData: [],
				seriesList: [], //商品系列列表
				goodData: '',
				selectData: '',
				gradeConsumeId: '' //等级id
			}
		},
		mounted() {
			this.finGoodSeriesAll()
			this.findGrade()
		},
		methods: {
			//查询
			submitSearch() {
				let levelTask = this.gradeConsumes.filter(item => item.id == this.retrieveForm.levelId)
				debugger
				this.$confirm(`您确认执行<span style="color:red;font-size:20px;">${this.retrieveForm.detailName}</span>等级修改为<span style="color:red;font-size:20px;">${levelTask[0].grade}</span>吗？`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					dangerouslyUseHTMLString:true,
					type: 'warning'
				}).then(_ => {
					this.getTableData(true)
					this.$message({
						message: '执行成功',
						type: 'success'
					})
				})
			},
			//获取表格数据
			getTableData(type) {
				this.tabLoading = true
				this.$http.post('/OthSys/editSysGoodDetailByDetailId', {
					detailName: this.retrieveForm.detailName,
					gradeId: this.retrieveForm.levelId,
					isEdit:type
				}).then(({
					data: result
				}) => {
					this.tabLoading = false
					this.tableData = result.data
					this.pageInfo.total = result.data.total
				})
			},
			//一番赏商品真实等级
			findGrade() {
				this.$http.get('/grade/findGrade').then(({
					data: result
				}) => {
					this.gradeConsumes = result.data
				})
			},
			handleSeries(e) {
				if(e){
					this.retrieveForm.detailId = ''
					this.rewardDetails(e)
				}
			},
			//获取详情数据
			rewardDetails(id) {
				this.$http.get(`/goodSeries/findGoodSeriesById?id=${id}`).then(({
					data: res
				}) => {
					let goodData = res.data
					if (goodData.goodDetailsList == null || goodData.goodDetailsList == undefined || goodData
						.goodDetailsList == "") {
						goodData.goodDetailsList = []
					}
					this.isSeriesType = goodData.seriesPattern == 1 ? true : false
					this.isOffDate = goodData.consume > 0 ? true : false
					this.isExch = goodData.exchOff == 0 ? false : true
					this.goodData = goodData
				})
			},
			finGoodSeriesAll() {
				this.$http.get('/goodSeries/finGoodSeriesAll').then((result) => {
					this.seriesList = result.data.data
				})
			},
			handGoodsLevel(even) {
				// let editData = this.goodData.goodDetailsList.filter(item => item.id == even)
				this.gradeConsumeId = even
			},
			//下拉框选择系列
			handleSeriesChang(even) {
				console.log(even)
				this.ruleForm.seriesName = this.seriesList.filter(ele => ele.id == even)[0].seriesName
			},
			// 选择框执行的操作
			handleSelectionChange(val) {
				let selectData = []
				val.forEach(item => {
					selectData.push(item.id + '-' + item.sysName)
				})
				this.selectData = selectData
			},
			editSubmit() {
				let strs = this.selectData.join(',')
				this.$confirm('您确认修改？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/OthSys/editSysGoodDetailByOthSysDetailId', {
						gradeId: this.gradeConsumeId,
						str: strs
					}).then(({
						data: result
					}) => {
						this.$message({
							message: '修改成功',
							type: 'success'
						})
						this.selectData = []
						this.getTableData(false)
					})
				}).catch(_ => {})
			},
			//编辑列表
			editList(row) {
				this.dialogTitle = '编辑'
				this.dialogFormVisible = true
				this.ruleForm = JSON.parse(JSON.stringify(row)) //排序
			},
			//确认弹框
			affirmPopup() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.butLoading = true
						let apiUrl = ''
						if (this.dialogTitle == '新增') {
							apiUrl = '/slideShow/addSlideShow'
						} else if (this.dialogTitle == '编辑') {
							apiUrl = '/slideShow/editSlideShow'
						}
						this.$http.post(apiUrl, this.ruleForm).then((result) => {
							this.dialogFormVisible = false
							this.getTableData()
							this.$message({
								message: this.dialogTitle + '成功',
								type: 'success'
							})
							this.butLoading = false
						}).catch(err => {
							this.butLoading = false
						})
					}
				})
			},

			//重置清除校验
			resetForm() {
				Object.assign(
					this.ruleForm,
					this.$options.data().ruleForm
				)
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate()
				})
			},
		},
	}
</script>

<style lang="less" scoped>
</style>