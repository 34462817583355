<template>
	<div class="systemSetDiv">
		<div class="systemFrom">
			<div class="fs-16 fw-bold mb-20">
				<span class="w-10 h-10 bgc-0076f6 mr-10 dis-in-block"></span>
				<span>微信支付配置</span>
			</div>
			<el-form :model="rulePayForm" ref="rulePayForm" label-width="150px">
				<el-form-item label="微信活动模板地址" prop="name">
					<el-input v-model="rulePayForm.actyPath"></el-input>
				</el-form-item>
				<el-form-item label="微信活动模板id" prop="name">
					<el-input v-model="rulePayForm.actyTemId"></el-input>
				</el-form-item>
				<el-form-item label="小程序appid" prop="name">
					<el-input v-model="rulePayForm.appId"></el-input>
				</el-form-item>
				<el-form-item label="小程序秘钥" prop="name">
					<el-input v-model="rulePayForm.appSecret"></el-input>
				</el-form-item>
				<el-form-item label="平台余额名称" prop="name">
					<el-input v-model="rulePayForm.blanceName"></el-input>
				</el-form-item>
				<el-form-item label="新品抽盒路径" prop="name">
					<el-input v-model="rulePayForm.boxPath"></el-input>
				</el-form-item>
				<el-form-item label="七牛云仓库名" prop="name">
					<el-input v-model="rulePayForm.bucketName"></el-input>
				</el-form-item>
				<el-form-item label="新品一番赏路径" prop="delivery">
					<el-input v-model="rulePayForm.kdSecret"></el-input>
				</el-form-item>
				<el-form-item label="快递100 Key" prop="delivery">
					<el-input v-model="rulePayForm.kdKey"></el-input>
				</el-form-item>
				<el-form-item label="快递100 secret" prop="delivery">
					<el-input v-model="rulePayForm.kdSiId"></el-input>
				</el-form-item>
				<el-form-item label="快递100设备Id" prop="delivery">
					<el-input v-model="rulePayForm.kdSiId"></el-input>
				</el-form-item>
				<el-form-item label="新品精品商城路径" prop="delivery">
					<el-input v-model="rulePayForm.mallPath"></el-input>
				</el-form-item>
				<el-form-item label="新品微信订阅模板id" prop="delivery">
					<el-input v-model="rulePayForm.manageTemId"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('rulePayForm')">保存</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>
<script>
	export default {
		name: '',
		components: {
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
		},
		data() {
			return {
				configData: [],
				WxIfo: [],
				rulePayForm: {
					actyPath: '', //微信活动模板地址
					actyTemId: '', //微信活动模板id
					appId: '', //小程序appid
					appSecret: '', //小程序秘钥	
					blanceName: '', //平台余额名称
					boxPath: '', //新品上线抽盒路径
					bucketName: '', //七牛云仓库名
					goodPath: '', //新品上线一番赏路径
					kdKey: '', //快递100 Key
					kdSecret: '', //快递100 secret	
					kdSiId: '', //快递100设备Id
					mallPath: '', //新品上线精品商城路径	
					manageTemId: '', //manageTemId	
				}
			}
		},
		mounted() {
			debugger
			this.findSystemConfig()
		},
		methods: {
			//基础数据 微信支付配置 保存
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let apiUrl = ''
						if (formName == 'rulePayForm') {
							apiUrl = '/restsConfig/editRestsConfig'
						}
						this.$http.post(apiUrl, this[formName]).then((result) => {
							this.$message({
								message: '保存成功',
								type: 'success'
							})
							if (formName == 'rulePayForm') {
								this.findSystemConfig()
							}
						}).catch((err) => {})
					} else {
						console.log('error submit!!')
						return false
					}
				})
			},
			//查询基础配置
			findSystemConfig() {
				this.$http.get('/restsConfig/findRestsConfig').then((result) => {
					let configData = result.data.data
					this.rulePayForm = configData
				}).catch((err) => {})
			},
		}
	}
</script>
<style lang="less" scoped>
	.systemSetDiv {
		height: 100%;
		background: #fff;
		overflow-y: scroll;

		.systemFrom {
			width: 1000px;
			padding: 10px 30px;
		}
	}
</style>