<template>
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv flex flex-jb">
				<div class="flex flex-ac">
				</div>
				<div class="flex flex-ac">
					<div class="ml-40 fs-16 fc-6b9eff pointer" @click="addList">
						<i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
					</div>
				</div>
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			<!-- 表格 -->
			<EleTable ref="tableRef" slot="table" :columns="columns" :tableData="tableData" height="100%" :border="false"
				@row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
				<template v-slot:image="scope">
					<img :src="scope.row.cardImg" alt="" class="smallImg" v-if="scope.row.cardImg" />
				</template>
			</EleTable>
			<!-- 分页 -->
		</TableHeightFull>
		<!-- 新增修改弹框 -->
		<el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="20vh" width="30%" @close="resetForm">
			<el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
				<el-form-item label="部门" prop="deptId" label-width="120px" v-if="'deptId' in ruleForm"
					:rules="{required: true, message: '部门不能为空', trigger: 'blur'}">
					<el-select class="w-250" v-model="ruleForm.deptId" clearable placeholder="请选择">
						<el-option v-for="item in departData" :key="item.id" :label="item.deptName" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="appId" label-width="120px" prop="appId" v-if="'appId' in ruleForm"
					:rules="{required: true, message: 'appId不能为空', trigger: 'blur'}">
					<el-input type="text" v-model="ruleForm.appId"></el-input>
				</el-form-item>
				<el-form-item label="商户号" label-width="120px" prop="mchId" v-if="'mchId' in ruleForm"
					:rules="{required: true, message: '商户号不能为空', trigger: 'blur'}">
					<el-input type="text" v-model="ruleForm.mchId"></el-input>
				</el-form-item>
				<el-form-item label="商户号密钥" label-width="120px" prop="apiKey" v-if="'apiKey' in ruleForm"
					:rules="{required: true, message: '商户号不能为空', trigger: 'blur'}">
					<el-input type="text" v-model="ruleForm.apiKey"></el-input>
				</el-form-item>
				<el-form-item label="AppSecret" label-width="120px" prop="appSecret" v-if="'appSecret' in ruleForm"
					:rules="{required: true, message: 'AppSecret不能为空', trigger: 'blur'}">
					<el-input type="text" v-model="ruleForm.appSecret"></el-input>
				</el-form-item>
				<el-form-item label="签名加密方式" label-width="120px" prop="signType" v-if="'signType' in ruleForm"
					:rules="{required: true, message: '签名加密方式不能为空', trigger: 'blur'}">
					<el-input type="text" v-model="ruleForm.signType"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import indexMixin from "@/utils/indexMixin"
	export default {
		name: 'gatherCard',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
		},
		data() {
			return {
				tabLoading: false,
				butLoading: false,
				columns: [
					{
						label: '小程序名称',
						prop: 'deptId',
						align: 'left',
						'show-overflow-tooltip': true,
						formatData: (e) => {
							if(this.departData.length > 0){
								let ele = this.departData.find(ele => ele.id === e)
								return ele ? ele.deptName : ''
							}
						}
					},
					{
						label: 'APPid',
						prop: 'appId',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						label: '商户号',
						prop: 'mchId',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						label: '商户号密钥',
						prop: 'apiKey',
						align: 'left',
						width:'300',
						'show-overflow-tooltip': true,
					},
					{
						label: 'AppSecret',
						prop: 'appSecret',
						align: 'left',
						width:'300',
						'show-overflow-tooltip': true,
					}, {
						label: '签名加密方式',
						prop: 'signType',
						align: 'left',
						'show-overflow-tooltip': true,
					},
					// {
					// 	columnType: 'fun',
					// 	label: '是否开启发货',
					// 	prop: 'sendMsgOff',
					// 	align: 'left',
					// 	'show-overflow-tooltip': true,
					// 	formatData: (e) => {
					// 		return e == 0 ? '否':'是'
					// 	}
					// }, 
					{
						columnType: 'button',
						label: '操作',
						align: 'right',
						fixed: 'right',
						buttons: [{
								type: 'text',
								text: '编辑',
								fn: this.editList
							},
							{
								type: 'text',
								text: '删除',
								fn: this.deleteList
							},
						],
					},
				],
				tableData: [],
				systemData: '', //活动时间
				//弹框
				dialogFormVisible: false,
				dialogTitle: '新增',
				ruleForm: {
					appId: '',
					mchId: '',
					apiKey: '',
					appSecret: '',
					deptId: '',
					signType: 'MD5'
				},
				departData: '',
			}
		},
		mounted() {
			this.getTableData()
		},
		methods: {
			//获取表格数据
			getTableData() {
				this.tabLoading = true
				this.$http.get('/wxInfo/findWxInfo').then(({
					data: result
				}) => {
					this.tabLoading = false
					this.tableData = result.data
					this.findDepartList()
				}).catch(err => {
					this.tabLoading = false
				})
			},
			findDepartList() {
				this.$http.get('/wxInfo/findDepartList').then(({
					data: result
				}) => {
					this.departData = result.data
				}).catch(err => {})
			},
			//跳转详情
			headerRowClick(row) {
				console.log('跳转详情')
				console.log(row)
			},
			//新增列表
			addList() {
				this.dialogTitle = "新增"
				this.dialogFormVisible = true
			},
			//编辑列表
			editList(row) {
				this.dialogTitle = "编辑"
				this.ruleForm = JSON.parse(JSON.stringify(row))
				this.dialogFormVisible = true
			},
			//删除列表
			deleteList(row) {
				this.$confirm('您确认删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/wxInfo/removeWxInfo', {
						id: row.id
					}).then(({
						data: result
					}) => {
						this.$message({
							message: '删除成功',
							type: 'success'
						})
						this.getTableData()
					})
				}).catch(_ => {})
			},
			//确认弹框
			affirmPopup() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.butLoading = true
						let apiUrl = ""
						if (this.dialogTitle == '新增') {
							apiUrl = "/wxInfo/addWxInfo"
						} else if (this.dialogTitle == '编辑') {
							apiUrl = "/wxInfo/editWxInfo"
						}
						this.$http.post(apiUrl, this.ruleForm).then(({
							data: result
						}) => {
							this.$message({
								message: this.dialogTitle + '成功',
								type: 'success'
							})
							this.butLoading = false
							this.getTableData()
							this.dialogFormVisible = false
						}).catch((err) => {
							this.butLoading = false
						})
					}
				})
			},
			//重置清除校验
			resetForm() {
				Object.assign(
					this.$data.ruleForm,
					this.$options.data().ruleForm
				)
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate()
				})
			},
		},
	}
</script>

<style lang="less" scoped>
</style>